import { subscribeSearchResultV2 } from '@/services/graphql/searching/asyncSearchSubscriptions';
import {
  ASYNC_SEARCH_RESULT,
  ASYNC_SEARCH_WORKSPACE_PAGES,
} from '@/constants/userPermissions';
import {
  mapActions, mapGetters,
} from 'vuex';
export default {
  data() {
    return {
      currentRequestId: null,
      currentParseMethod: null,
    };
  },
  computed: {
    ...mapGetters({
      username: 'userId',
      isSubscriptionExist: 'ManageSubscriptions/isSubscriptionExist',
    }),
  },
  methods: {
    ...mapActions({
      handleError: 'handleError',
      setSubscription: 'ManageSubscriptions/setSubscription',
      removeSubscriptions: 'ManageSubscriptions/removeSubscriptions',
    }),
    mappingNewAttachment(obj) {
      const { data: dataItems = [] } = obj || {
      };
      const data = dataItems.reduce((result, current) => {
        const { attachment: arrAttach } = current || {
        };
        const attachment = arrAttach ? arrAttach.map(e => e?.attachment) : null;
        const item = {
          ...current, ...(attachment && {
            attachment,
          }),
        };
        return [...result, item];
      }, []);
      return {
        ...obj, data,
      };
    },
    async unsubscribe(subTitle = '') {
      if (subTitle) {
        this.removeSubscriptions([
          subTitle,
        ]);
      } else {
        await this.removeSubscriptions([
          ASYNC_SEARCH_RESULT,
          ASYNC_SEARCH_WORKSPACE_PAGES,
        ]);
      }
    },
    async asyncSearchingSubscription({
      callback,
      subscriptionTitle: title = ASYNC_SEARCH_RESULT,
      subscriptionFunc = subscribeSearchResultV2,
      canMapNewAttachments = true,
    } = {
    }) {
      const {
        handleError,
        username,
        setSubscription,
        isSubscriptionExist,
      } = this;
      if (isSubscriptionExist(title)) return;
      try {
        const subscription = await subscriptionFunc({
          username,
        }, ({ data: respData }) => {
          const { requestId, ...data } = respData?.response ?? {
          };
          const newData = canMapNewAttachments ? this.mappingNewAttachment(data) : data;
          if (this.currentRequestId !== requestId) return;
          callback({
            data: newData,
          });
        });
        setSubscription({
          subscription,
          title,
        });
      } catch (err) {
        handleError(err);
      }
    },
  },
};

<template>
  <div
    class="d-flex"
    :style="{'justifyContent' : expandedRow ? 'flex-start' : 'center'}">
    <v-switch
      v-model="valueSwitch"
      class="switcher"
      :disabled="disabledEditMode || !hasAccessEditor"
      hide-details
      @change="handleChange" />
  </div>
</template>
<script>
import EditModeChildComponent from '@/mixins/EditModeChildComponent';
export default {
  name: 'ProductCellCheckboxV2',
  mixins: [EditModeChildComponent],
  props: {
    item: {
      type: Object,
      default: null,
    },
    hasAccessEditor: {
      type: Boolean,
      default: true,
    },
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueSwitch: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        const parseValue = typeof value === 'boolean' ? value : value === 'true';
        this.valueSwitch = parseValue;
      },
    },
  },
  methods: {
    handleChange() {
      const value = this.valueSwitch.toString();
      this.$emit('change', {
        value,
      });
    },
  },
};
</script>
<style scoped lang="scss">
  ::v-deep .v-icon.v-icon {
    display: block !important
  }
  .switcher {
    margin: 0 !important;
    padding: 0 !important;
  }
</style>

import { hasEditorAccess } from '@/utils';
import {
  mapState, mapGetters,
} from 'vuex';
export default {
  computed: {
    ...mapState(['role', 'roles']),
    ...mapGetters('UserRoles', ['findUserRoleInLibraryForActiveHeader']),
    ...mapGetters('Collections', ['isReadOnlyModalVariant']),
    disabledEditMode() {
      if (this.$route?.name?.includes('collection') && this.isReadOnlyModalVariant) {
        return true;
      }
      if (this.isProductPreview) {
        return true;
      } else if (this.$route.path.includes('/shared')) {
        return true;
      } else if (['collections'].includes(this.$route.name)) {
        return false;
      } else if (['collection-library', 'collection-product-link'].includes(this.$route.name)) {
        return !hasEditorAccess(this.roles);
      }
      return Boolean(!hasEditorAccess(this.role) || this.$route.query.version);
    },
  },
};

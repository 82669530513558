<template>
  <fragment>
    <!-- Collections search component -->
    <CollectionsSearch
      v-bind="propsDataForSearching" />

    <div
      v-if="!searchProductsMode"
      class="sourcery__container d-flex flex-column gap-6 pb-25">
      <AppEmptyList
        v-if="!isLoading && isEmpty"
        from="community" />

      <template v-else>
        <!-- options bar -->
        <div
          :class="[
            'd-flex',
            $vuetify.breakpoint.mobile ? 'flex-wrap gap-4 justify-start' : 'justify-space-between',
          ]">
          <div>
            <AppSelect
              :items="groupsArrToRendering"
              :switch-selected="switchSelect"
              @resort="selectedCollectionGroup = $event" />
          </div>

          <div class="d-flex align-center gap-4">
            <AppSort
              :default-item="getSortedItem"
              :sort-items="COLLECTION_SORT_ITEMS"
              @resort="$emit('sortCommunity', $event)" />
            <AppSwitcherToListing />
          </div>
        </div>

        <component
          :is="isListingView ? 'CollectionListing' : 'CollectionsTable'"
          :collections-list="filteredList"
          :group="selectedCollectionGroup.group"
          @click-action-menu="onClickActionMenu"
          @onIntersect="$emit('onIntersect', $event)" />
      </template>
    </div>

    <!-- Product dialog component -->
    <ProductDialog />
    <ShareAdvancedDialog
      :start-subscribe="true"
      :invite-to-text-type="COLLECTION"
      :item="activeCollection"
      @manageSharedUser="
        manageSharedUser({
          variables: {
            ...$event.variables,
            collectionId: activeCollection.id,
          },
          criteria: $event.criteria,
        })
      " />
    <Actions
      :show-activator="false"
      :item.sync="selectedItem"
      v-bind="actionsProps"
      :position-x="positionX"
      :position-y="positionY"
      is-community-collections
      :value.sync="showActionsCard"
      @manageActions="$emit('manageActions', $event)" />
  </fragment>
</template>
<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import { COLLECTION_SORT_ITEMS } from '@/constants/sortable';
import { COLLECTION } from '@/constants/cores';

// components
import Actions from '@/components/Collections/CollectionsActions';
import AppSelect from '@/components/App/AppSelect';
import AppSort from '@/components/App/AppSortV2';
import AppSwitcherToListing from '@/components/App/AppListingElements/AppSwitcherToListing';
import CollectionListing from '@/components/Listing/Collections';
import CollectionsSearch from '@/components/Collections/CollectionsSearch';
import CollectionsTable from '@/components/Collections/CollectionsTable';
import ProductDialog from '@/components/Collections/CollectionsCreateProductDialog';
import ShareAdvancedDialog from '@/components/CollectionsLibrarysRelocate/ShareAdvancedDialog';

// mixins
import AppActionDotsMenu from '@/mixins/AppActionDotsMenu';
import GetInfo from '@/mixins/getInfo';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';

export default {
  name: 'CommunityLayout',
  components: {
    Actions,
    AppSelect,
    AppSort,
    AppSwitcherToListing,
    CollectionListing,
    CollectionsSearch,
    CollectionsTable,
    ProductDialog,
    AppEmptyList: () => import('@/components/App/AppEmptyList'),
    ShareAdvancedDialog,
  },
  mixins: [
    ToggleViewCondition,
    AppActionDotsMenu,
    GetInfo,
  ],
  props: {
    dataStore: {
      type: Object,
      default: () => {
      },
    },
    groupsArrToRendering: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    propsDataForSearching: {
      type: Object,
      default: () => {
      },
    },
    propsDataToSections: {
      type: Object,
      default: () => {
      },
    },
    sortCommunity: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      COLLECTION,
      COLLECTION_SORT_ITEMS,
      actionsProps: {
      },
      selectedCollectionGroup: null,
      switchSelect: null,
    };
  },
  computed: {
    ...mapGetters('Collections', ['getSortedItem']),
    ...mapState('Collections', ['activeCollection', 'searchProductsMode']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    filteredList() {
      const { selectedCollectionGroup, dataStore } = this;
      return dataStore[selectedCollectionGroup.mappingName];
    },
    // check if default community page is empty
    isEmpty() {
      const collectedArr = Object.values(this.dataStore).reduce((accumulator, arr) => {
        return [ ...accumulator, ...arr ];
      }, []);

      return collectedArr.length == 0;
    },
    isListingView() {
      return this.toggleViewCondition('librariesRelatedPages');
    },
  },
  watch: {
    /**
     * Once loading is complete, check which section has values
     * and view that section
     */
    isLoading(val) {
      if (val) return;

      const { groupsArrToRendering, dataStore } = this;

      groupsArrToRendering.some((item, index) => {
        let { mappingName } = item;
        let data = dataStore[mappingName];

        if (data.length && index === 0) {
          return true;
        } else if (data.length) {
          this.switchSelect = item;
          this.selectedCollectionGroup = item;
          return true;
        }
      });
    },
  },
  created() {
    this.selectedCollectionGroup = this.groupsArrToRendering[0];
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
    }),
    ...mapActions({
      manageSharedUser: 'Collections/manageSharedUser',
    }),
    onClickActionMenu({ event, item } = {
    }) {
      const {
        group,
        actionTypes: actionsConditions,
        showActions,
        kind = 'default',
      } = this.selectedCollectionGroup;

      this.setMenuItem({
        item,
        event,
      });

      this.actionsProps = {
        group,
        actionsConditions,
        showActions,
        kind,
      };
    },
  },
};
</script>

<template>
  <div
    v-if="isImagePdf && !isThumbnail"
    class="wrapper-pdf"
    :style="[!disabledCustomSizePdf && previewPdfStyles[fileId]]"
    @click.stop="setFilesPreview">
    <!-- For description with reduceProportions see image-preview__file class -->
    <AppProxyPdf
      :ref="'pdf-'.concat(fileId)"
      class="image-preview__img file-image__pdf"
      :disabled-custom-size-pdf="disabledCustomSizePdf"
      :reduce-proportions="92"
      :is-expanded-mode="isExpandedMode"
      :size-settings="getSizeSettings"
      :src="url"
      @loaded="onPdfLoad"
      @progress="onPdfProgress" />
  </div>
  <img
    v-else-if="url"
    :src="url"
    :style="{ maxHeight: imageMaxH }"
    class="image-preview__img pointer"
    :alt="file.filename"
    @error="onError"
    @click.stop="setFilesPreview">
</template>
<script>
import AppProxyPdf from '@/components/App/AppProxyPdf/index.vue';
import {
  mapGetters,
  mapActions,
} from 'vuex';
import { DEFAULT_THUMBNAILS_SIZES } from '@/constants/thumbnails';
export default {
  name: 'ImgPdfFile',
  components: {
    AppProxyPdf,
  },
  props: {
    // If empty - use original image.
    thumbnailSize: {
      type: String,
      default: '',
    },
    disabledCustomSizePdf: {
      type: Boolean,
      default: false,
    },
    previewPdfStyles: {
      type: Object,
      default: () => {
      },
    },
    file: {
      type: Object,
      required: true,
    },
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    transformImagesToData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      url: '',
      isThumbnail: false,
      latestImageRequest: null,
    };
  },
  computed: {
    ...mapGetters('Images', ['getImageOrThumbnailKey', 'getImageFetchPromise']),
    fileId() {
      return this.file?.id;
    },
    getSizeSettings() {
      if (this.isExpandedMode) {
        return null;
      }
      if (this.isThumbnail) {
        return this.file?.thumbnails?.[this.thumbnailSize];
      }
      return DEFAULT_THUMBNAILS_SIZES()[this.thumbnailSize];
    },
    isImagePdf() {
      return this.file?.type === 'application/pdf';
    },
    cachedImageKey() {
      return this.getImageOrThumbnailKey(this.fileId, this.thumbnailSize);
    },
    imageMaxH() {
      return this.getSizeSettings ? `${this.getSizeSettings.height}px` : '104px';
    },
  },
  watch: {
    file: {
      deep: true,
      immediate: true,
      async handler(val) {
        if (!val) return;
        this.fetchImage(val);
      },
    },
    thumbnailSize() {
      this.fetchImage(this.file);
    },

    cachedImageKey: {
      async handler(val) {
        if (!this.transformImagesToData) return;
        if (val) {
          const req = this.getImageFromCache(val);
          this.latestImageRequest = req;

          const res = await req;
          if (this.latestImageRequest == req) {
            this.url = res;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('Images', ['fetchDataUrl', 'getImageFromCache']),

    async fetchImage(val) {
      if (!val) return;
      let fetchUrl = val.url;

      if (this.thumbnailSize) {
        let thumbUrl;
        if (val.thumbnails) {
          const thumbObj = val.thumbnails[this.thumbnailSize];
          if (thumbObj) {
            thumbUrl = thumbObj.url;
            fetchUrl = thumbUrl || fetchUrl;
            this.isThumbnail = Boolean(thumbUrl);
          }
        }
      }
      if (!fetchUrl || !this.transformImagesToData) {
        this.url = fetchUrl;
      } else {
        if (fetchUrl) {
          const req = this.fetchDataUrl({
            key: this.fileId,
            size: this.thumbnailSize,
            url: fetchUrl,
          });
          this.latestImageRequest = req;
          const res = await req;
          if (this.latestImageRequest == req) {
            this.url = res;
          }
        }
      }
    },
    onPdfLoad() {
      const { file, fileId } = this;
      const pdfProvider = this.$refs['pdf-'.concat(fileId)].pdf;
      this.$emit('onPdfLoad', file, pdfProvider);
    },
    onPdfProgress() {
      this.$emit('onPdfProgress');
    },
    setFilesPreview() {
      this.$emit('setFilesPreview');
    },
    onError() {
      this.$emit('onErrorCustom', this.file);
    },
  },
};
</script>

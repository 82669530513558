<template>
  <app-kebab-actions
    v-bind="$attrs"
    v-on="$listeners">
    <slot :item="item">
      <app-action-btn
        :access="accessTooltip"
        :show-tooltip="!(accessTooltip && accessTooltip.allowed)"
        bottom
        @click="remove">
        <v-icon
          size="17">
          mdi-delete
        </v-icon>
        Delete
      </app-action-btn>
    </slot>
  </app-kebab-actions>
</template>
<script>
import AppKebabActions from '@/components/App/AppKebabActions';
export default {
  name: 'DocumentCellActions',
  components: {
    AppKebabActions,
  },
  props: {
    isDisabledDelete: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    accessTooltip() {
      return this.isDisabledDelete ?
        {
          allowed: false,
          reason: 'single_delete',
        } : this.item?.canModifyNotOwnProjectDocuments;
    },
  },
  methods: {
    remove() {
      if (!this.accessTooltip?.allowed) return false;
      this.$emit('removeDocument', this.item);
    },
  },
};
</script>
<style scoped>
</style>

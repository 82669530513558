import {
  COLLECTIONS_CRITERIA, PRODUCTS_CRITERIA,
} from '@/constants/searching/searchingAsyncV2';
export default {
  props: {
    orderOfSections: {
      type: Array,
      default: () => ([
        COLLECTIONS_CRITERIA,
        PRODUCTS_CRITERIA,
      ]),
    },
    searchPlaceholder: {
      type: String,
      default: undefined,
    },
    libraryId: {
      type: String,
      default: null,
    },
    /**
     * Query for async collections searching
     * @param variables
     * @returns {object}
     */
    asyncCollectionsApiMethod: {
      type: Object,
      default: () => {
      },
    },
    /**
     * Query for async products searching
     * @param variables
     * @returns {object}
     */
    asyncProductsApiMethod: {
      type: Object,
      default: () => {
      },
    },
    /**
     * Query for async collections and products searching
     * @param variables
     * @returns {object}
     */
    asyncCollectionsAndProductsApiMethod: {
      type: Object,
      default: () => {
      },
    },
    /**
     * Query for async workspace pages searching
     * @param variables
     * @returns {object}
     */
    searchWorkspacePages: {
      type: Object,
      default: () => {
      },
    },
    arrNameRequest: {
      type: Array,
      default: () => [],
    },
  },
};

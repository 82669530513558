const TYPE_DOCUMENTS = [
  {
    title: 'Design Documents',
    id: 1,
    type: 'designDocument',
    column: 'Associated Design Documents',
  },
  {
    title: 'Submittals',
    id: 2,
    type: 'submittals',
    column: 'Associated Submittals',
  },
];
export { TYPE_DOCUMENTS };

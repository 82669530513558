<template>
  <a
    :style="iesFileStyles"
    :href="url"
    @click.prevent="openAttachmentInNewWindow">
    <div class="d-flex justify-center">
      <v-icon :size="isSizeIesFile">
        mdi-file-document
      </v-icon>
    </div>
    <div
      v-if="showNameFile"
      class="text-truncate">
      {{ name }}
    </div>
  </a>
</template>
<script>
export default {
  name: 'DocumentFile',
  props: {
    showNameFile: {
      type: Boolean,
      default: true,
    },
    isSizeIesFile: {
      type: Number,
      default: 90,
    },
    iesFileStyles: {
      type: Object,
      default: () => {
      },
    },
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name() {
      const { filename } = this.file;
      return filename;
    },
    url() {
      return this.file?.url || '';
    },
  },
  methods: {
    openAttachmentInNewWindow() {
      this.$emit('openAttachmentInNewWindow', this.url);
    },
  },
};
</script>
<style scoped>
</style>

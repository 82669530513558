import { genRanHex } from '@/utils';
import store from '@/store';
import {
  PutTraceSegmentsCommand, XRayClient,
} from '@aws-sdk/client-xray';
export default class XRay {
  #region = process.env.VUE_APP_AWS_REGION || 'us-east-1';
  #segment = {
    name: '',
    id: '',
    start_time: 0,
    end_time: 0,
    trace_id: '',
    parent_id: '',
  };
  #segments = [];
  get getCurrentDate() {
    return Date.now() / 1000;
  }
  get segments() {
    return this.#segments;
  }
  set segments(val) {
    this.#segments = val;
  }
  set addSegment(val) {
    this.#segments.push(val);
  }
  get getArrTracedId() {
    return this.segments.map(e => e.trace_id);
  }
  get arrJsonSegments() {
    return this.segments.map(e => JSON.stringify(e));
  }
  get xrayInstance() {
    const {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    } = store.state.UserProfile?.credentials || {
    };
    return new XRayClient({
      region: this.#region,
      credentials: {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      },
    });
  }
  get getSegmentId() {
    return genRanHex(16);
  }
  getTriceId(nameRequest) {
    const { start_time } = this.segments.find(e => e.name === nameRequest) || {
    };
    const hexRandomId = genRanHex(24);
    const hexIdStartTime = this.getHexIdFromIntDate(start_time);
    return `1-${hexIdStartTime}-${hexRandomId}`;
  }
  getHexIdFromIntDate(data) {
    const intDate = parseInt(data);
    return intDate.toString(16);
  }
  startTimeTracker(nameRequest) {
    if (!nameRequest) return false;
    const { getCurrentDate: start_time } = this;
    this.addSegment = {
      start_time, name: nameRequest,
    };
  }
  stopTimeTracker(nameRequest) {
    if (!nameRequest) return;
    const { getCurrentDate: end_time, getSegmentId: id } = this;
    const trace_id = this.getTriceId(nameRequest);
    const data = {
      name: nameRequest,
      end_time,
      id,
      trace_id,
    };
    this.#setSegment(data);
  }
  #setSegment(data) {
    const { segments } = this;
    const { name: nameRequest = '' } = data;
    const segment = {
      ...data,
    };
    this.segments = segments.reduce((result, current) => {
      const { name } = current;
      const data = nameRequest === name ? {
        ...current, ...segment,
      } : current;
      const idCurrentSegments = result.map(e => e.id || '') || [];
      return idCurrentSegments.includes(data.id) ? result : [...result, data];
    }, []);
  }
  filterSegments() {
    const filtered = this.segments.reduce((result, current) => {
      const { id: parent_id = '' } = result?.[result.length - 1] || {
      };
      const data = parent_id ? {
        ...current, parent_id,
      } : current;
      const { id = '' } = data || {
      };
      return id ? [...result, data] : result;
    }, []);
    this.segments = filtered;
  }
  sendSegmentsToAws() {
    try {
      this.filterSegments();
      const { xrayInstance, arrJsonSegments: TraceSegmentDocuments } = this;
      if (!TraceSegmentDocuments.length) return;
      const command = new PutTraceSegmentsCommand({
        TraceSegmentDocuments,
      });
      xrayInstance.send(command).catch(err => {
        console.log(err);
      });
      this.segments = [];
    } catch (err) {
      console.log(err);
    }
  }
}

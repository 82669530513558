/** Possible cases for keyboard events */
const KEYBOARD_KEYS = {
  S: 83,
  UP: 38,
  DOWN: 40,
  RIGHT: 39,
  LEFT: 37,
  ENTER: 13,
  TAB: 9,
  ESC: 27,
};
/** Config for scroll moving */
const SCROLL_CONFIG = {
  behavior: 'smooth',
  block: 'nearest',
  inline: 'center',
};
export { KEYBOARD_KEYS, SCROLL_CONFIG };

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',_vm._b({scopedSlots:_vm._u([{key:"list",fn:function(ref){
  var filterHeadersByFlexItem = ref.filterHeadersByFlexItem;
  var isRequiredField = ref.isRequiredField;
  var canBeRendered = ref.canBeRendered;
  var isDialogCollection = ref.isDialogCollection;
  var collectionId = ref.collectionId;
  var renderClass = ref.renderClass;
  var searchMode = ref.searchMode;
  var showPensil = ref.showPensil;
  var view = ref.view;
  var disabledEditMode = ref.disabledEditMode;
  var isAsideForm = ref.isAsideForm;
  var isLoading = ref.isLoading;
return [_vm._l((filterHeadersByFlexItem),function(headerItem,i){return [(canBeRendered(headerItem , _vm.item) && !(_vm.isAsideHeader(headerItem.text) && !isAsideForm))?_c('div',{key:((headerItem.value) + "__" + i),class:['collection-products-list', _vm.isTopSeparator(headerItem.text)]},[_c('ProductCell',{staticClass:"pa-0",class:[renderClass(headerItem)],style:({ justifyContent: headerItem.align === 'right' ? 'flex-end' : 'inherit' }),attrs:{"collection-id":collectionId,"disabled-edit-mode":_vm.isHeaderDisabledForEditing(disabledEditMode, headerItem),"expanded-row":!showPensil(headerItem.column.type),"header":headerItem,"is-dialog-collection":isDialogCollection,"is-loading":_vm.isHeaderLoading(headerItem.text),"is-required":isRequiredField(headerItem.id),"item":_vm.item,"mapped-dropdowns-of-view":_vm.mappedDropdownsOfView,"search-mode":searchMode,"should-expand-height":_vm.isAsideHeader(headerItem.text),"view":view,"open-link-in-new-tab":""},on:{"updateProductField":function($event){return _vm.$emit('updateProductField', $event)}}})],1):_vm._e()]}),(isLoading)?[(!isAsideForm)?_c('ProductCellFormSkeleton',{staticClass:"mt-8 ml-4"}):_c('ProductCellFormAsideSkeleton',{staticClass:"mt-2 ml-4"})]:_vm._e()]}}])},'BaseLayout',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }
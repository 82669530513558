<template>
  <div class="product-cell-form-skeleton px-3 mt-6">
    <div
      v-for="n in 2"
      :key="n"
      class="product-cell-form-skeleton__list-item">
      <v-skeleton-loader
        class="list-item-header"
        type="text" />
      <v-skeleton-loader
        class="list-item-text"
        type="text" />
    </div>
    <div class="d-flex flex-column flex-sm-row mb-4">
      <div
        v-for="n in 2"
        :key="n"
        class="product-cell-form-skeleton__image-item">
        <v-skeleton-loader
          class="image-item-header"
          type="text" />
        <v-skeleton-loader
          class="image-item-image"
          type="image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCellFormSkeleton',
};
</script>

<style scoped lang="scss">
 ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__text {
      height: 100%;
    }
  }
  ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__image {
      height: 100%;
    }
  }
  .product-cell-form-skeleton {
    max-width: 85%;
  }
  .product-cell-form-skeleton__list-item {
    display: flex;
    gap: 1rem;
    height: 36px;
    margin-bottom: 1rem;
  }
  .list-item-header {
    width: 25%;
  }
  .list-item-text {
    width: 75%;
  }
  .product-cell-form-skeleton__image-item {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .image-item-header {
    width: 50%;
    height: 36px;
    max-width: 150px;
  }
  .image-item-image {
    aspect-ratio: 1;
    width: 80%;
  }
</style>
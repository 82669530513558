import { API } from 'aws-amplify';

/**
 * Request Abort Controller.
 * - Uses the browser's AbortController.
 * - The idea is the component can abort a request anytime (from being destroyed/user action/etc)
 *   and it propagates to the store action and graphql service.
 *
 * Usage
 * - Create one for EACH set of requests that you want to abort separately.
 * - Pass to store action.
 * - See created() in ProjectsList Component for how controller is created in component and passed.
 * - Call abort() when you need to cancel. Usually good in beforeDestroy().
 */
export default class RequestAbortController {
  constructor() {
    // These should be the graphQL request you get when you do
    // API.graphql() in services/graphql/<service>.
    this.gql_requests = [];
    this.controller = new AbortController();
    this.signal = this.controller.signal;
  }

  addRequest(req) {
    if (req) {
      this.gql_requests.push(req);
    } else {
      console.warn('Adding undefined request to RequestAbortController');
    }
  }

  abort({ message } = {
    message: 'Operation Aborted (Default)',
  }) {
    this.controller.abort();
    for (const req of this.gql_requests) {
      API.cancel(req, message);
    }
  }

  aborted() {
    return this.controller?.signal?.aborted;
  }
}

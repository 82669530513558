<template>
  <AppFormRow
    v-if="canBeRendered"
    :sm-cols="smCols"
    :single-item="singleItem"
    :filter-names-group-items="filterNamesGroupItems"
    :is-required-field="isRequiredField"
    :header-text="getHeaderText">
    <template #rowCell>
      <p
        v-if="notAccessible"
        class="x-small-p mb-0 mt-2">
        NOT ACCESSIBLE
      </p>
      <ProductCell
        v-else-if="!loading"
        :is-dialog-collection="isDialogCollection"
        :collection-id="collectionId"
        :class="cellClass"
        :view="view"
        :mapped-dropdowns-of-view="mappedDropdownsOfView"
        :expanded-row="expandedRow"
        :header="header"
        :disabled-edit-mode="readOnly ? true : disabledEditMode"
        :item="product"
        class="pa-0"
        open-link-in-new-tab
        :search-mode="searchMode"
        :style="{ justifyContent: header.align === 'right' ? 'flex-end' : 'inherit' }"
        @updateProductField="$emit('updateProductField', $event)" />
      <v-skeleton-loader
        v-else
        :class="getSkeletonContainerClass"
        tile
        :type="getSkeletonType" />
    </template>
  </AppFormRow>
</template>
<script>
import AppFormRow from '@/components/App/AppFormRow';
import ProductCell from '@/components/Product/ProductCell';
export default {
  name: 'AppFormRowToProductCell',
  components: {
    AppFormRow,
    ProductCell,
  },
  props: {
    canBeRendered: {
      type: Boolean,
      default: false,
    },
    isRequiredField: {
      type: Boolean,
      default: false,
    },
    singleItem: {
      type: Boolean,
      default: false,
    },
    filterNamesGroupItems: {
      type: Array,
      default: () => [],
    },
    searchMode: {
      type: Boolean,
      default: false,
    },
    expandedRow: {
      type: Boolean,
      default: false,
    },
    collectionId: {
      type: String,
      default: null,
    },
    openLinkInNewTab: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Object,
      default: () => {
      },
    },
    product: {
      type: Object,
      default: () => {
      },
    },
    cellClass: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    shortTitle: {
      type: String,
      default: '',
    },
    view: {
      type: String,
      default: 'productCreate',
    },
    disabledEditMode: {
      type: Boolean,
      default: true,
    },
    isDialogCollection: {
      type: Boolean,
      default: true,
    },
    smCols: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    notAccessible: {
      type: Boolean,
      default: false,
    },
    mappedDropdownsOfView: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    getHeaderText() {
      const { header, shortTitle } = this;
      const { value } = header || {
      };
      if (!shortTitle || this.$vuetify.breakpoint.mdAndUp) {
        return value;
      }
      return `${value}\n(${shortTitle})`;
    },
    getSkeletonType() {
      const { columnType: type } = this;
      return type === 'ARRAY_AIRTABLE_IMAGE' ? 'image' : 'text';
    },
    getSkeletonContainerClass() {
      if (!this.isGroupedType) return '';
      return `v-skeleton-loader__container--image`;
    },
    columnType() {
      const { column } = this.header || {
      };
      const { type } = column || {
      };
      return type;
    },
    isGroupedType() {
      const { value } = this.header || {
      };
      return this.filterNamesGroupItems.includes(value);
    },
  },
};
</script>
<style lang="scss" scoped>
  .v-skeleton-loader {
    &__container--image {
      aspect-ratio: 1/1;
      ::v-deep .v-skeleton-loader__bone {
        height: 100%;
        max-height: inherit;
      }
    }
    ::v-deep &__text {
      height: 40px;
    }
    ::v-deep &__image {
      max-height: 124px;
    }
  }
</style>

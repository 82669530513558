import { mapGetters } from 'vuex';
export default {
  data: () => ({
    attempts: {
    },
    attemptsAmount: 3,
  }),
  computed: {
    ...mapGetters('FeatureFlags', ['useIncreaseCaching']),
  },
  methods: {
    addAttempts(key) {
      if (!this.attempts[key]) {
        this.attempts[key] = 0;
      }
      this.attempts[key]++;
    },
    haveEnoughAttempts(key) {
      if (!this.useIncreaseCaching) {
        return false;
      }
      return this.attempts[key] < this.attemptsAmount;
    },
  },
};

export default {
  computed: {
    headersForRendering() {
      if (!this.$vuetify.breakpoint.smAndDown) {
        return this.headers;
      }
      const { MOBILE_COLS_TO_RENDERING: cols } = this;
      const headers = this.headers.filter(({ value }) => cols.includes(value));
      return headers;
    },
  },
};

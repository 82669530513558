import ProductCellTypeColV2 from '@/components/Product/ProductCell/ProductCellTypeColV2';
import ProductCellDropdownV2 from '@/components/Product/ProductCell/ProductCellDropdownV2';
import ProductCellStringV2 from '@/components/Product/ProductCell/ProductCellStringV2';
import ProductCellCurrencyV2 from '@/components/Product/ProductCell/ProductCellCurrencyV2';
import ProductCellNumberV2 from '@/components/Product/ProductCell/ProductCellNumberV2';
import ProductCellDateV2 from '@/components/Product/ProductCell/ProductCellDateV2';
import ProductCellTextareaV2 from '@/components/Product/ProductCell/ProductCellTextareaV2';
import ProductCellCurrencyWithUnitV2 from '@/components/Product/ProductCell/ProductCellCurrencyWithUnitV2';
import ProductCellUrlMultilineV2 from '@/components/Product/ProductCell/ProductCellUrlMultilineV2';
import ProductCellCheckboxV2 from '@/components/Product/ProductCell/ProductCellCheckboxV2';
import ProductCellAttachmentV2 from '@/components/Product/ProductCell/ProductCellAttachmentV2';
import ProductCellSelectV2 from '@/components/Product/ProductCell/ProductCellSelectV2';
import ProductAssociatedDocumentV2 from '@/components/Product/ProductCell/ProductAssociatedDocumentV2';
const DROPDOWN_COMPONENT = ProductCellDropdownV2;
const STRING_COMPONENT = ProductCellStringV2;
const CURRENCY_COMPONENT = ProductCellCurrencyV2;
const NUMBER_COMPONENT = ProductCellNumberV2;
const DATE_COMPONENT = ProductCellDateV2;
const TEXTAREA_COMPONENT = ProductCellTextareaV2;
export default {
  DROPDOWN: DROPDOWN_COMPONENT,
  COMBOBOX: DROPDOWN_COMPONENT,
  FIELD_PRODUCT_REFERENCE: () => import('@/components/Product/ProductCell/ProductCellFieldProduct/ProductCellFieldProductReference'),
  FIELD_PRODUCT_EQUAL: () => import('@/components/Product/ProductCell/ProductCellFieldProduct/ProductCellFieldProductEqual'),
  TYPE_COL: ProductCellTypeColV2,
  SINGLE: STRING_COMPONENT,
  FORMULA_STRING: STRING_COMPONENT,
  CURRENCY: CURRENCY_COMPONENT,
  FORMULA_CURRENCY: CURRENCY_COMPONENT,
  CURRENCY_WITH_UNIT: ProductCellCurrencyWithUnitV2,
  NUMBER: NUMBER_COMPONENT,
  FORMULA_NUMBER: NUMBER_COMPONENT,
  URL_MULTILINE: ProductCellUrlMultilineV2,
  DATE: DATE_COMPONENT,
  FORMULA_DATE: DATE_COMPONENT,
  CHECKBOX: ProductCellCheckboxV2,
  ARRAY_STRING: TEXTAREA_COMPONENT,
  MULTILINE: TEXTAREA_COMPONENT,
  ARRAY_AIRTABLE_IMAGE: ProductCellAttachmentV2,
  SELECT: ProductCellSelectV2,
  ASSOCIATED_DOCUMENTS: ProductAssociatedDocumentV2,
};

import requestBuilder from '@/services/graphql/requestBuilder';
import { API } from 'aws-amplify';
import {
  PROJECT_DOCUMENT, PROJECT_DOCUMENTS_CONNECTION,
} from '@/services/graphql/fragments';
import { GRAPHQL_TYPES } from '@/constants/cores';
const DOCUMENT = `
  {
    scheduleId
    libraryId
    id
    filename
    key
    type
    size
  }
`;
export default {
  getProjectDocuments(variables) {
    const query = `
    query getProjectDocuments($tableId : String! , $tableType : TableType!){
      response:getProjectDocuments(
        tableId: $tableId
        tableType: $tableType
      ) ${DOCUMENT}
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getProjectDocumentsV2(variables) {
    return new requestBuilder({
      requestName: 'getProjectDocumentsV2',
      requestVariables: {
        scheduleId: 'String!',
        workspaceId: 'String!',
        projectId: 'String!',
        documentType: 'ProjectDocumentTypes!',
        limit: 'Int',
        nextToken: 'String',
      },
      response: PROJECT_DOCUMENTS_CONNECTION,
      variables,
    }).processRequest();
  },
  getDeletedProjectDocuments(variables) {
    return new requestBuilder({
      requestName: 'getDeletedProjectDocuments',
      requestVariables: {
        scheduleId: 'String!',
        workspaceId: 'String!',
        projectId: 'String!',
        documentType: 'ProjectDocumentTypes!',
        limit: 'Int',
        nextToken: 'String',
      },
      response: PROJECT_DOCUMENTS_CONNECTION,
      variables,
    }).processRequest();
  },
  deleteProjectDocument(variables) {
    return new requestBuilder({
      requestName: 'deleteProjectDocument',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        scheduleId: 'String!',
        workspaceId: 'String!',
        projectId: 'String!',
        documentId: 'String!',
      },
      response: PROJECT_DOCUMENT,
      variables,
    }).processRequest();
  },
  restoreProjectDocument(variables) {
    return new requestBuilder({
      requestName: 'restoreProjectDocument',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        scheduleId: 'String!',
        workspaceId: 'String!',
        projectId: 'String!',
        documentId: 'String!',
      },
      response: PROJECT_DOCUMENT,
      variables,
    }).processRequest();
  },
  addProjectDocumentAssociatedType(variables) {
    return new requestBuilder({
      requestName: 'addProjectDocumentAssociatedType',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        scheduleId: 'String!',
        workspaceId: 'String!',
        projectId: 'String!',
        documentId: 'String!',
        associatedRowId: 'String!',
      },
      response: PROJECT_DOCUMENT,
      variables,
    }).processRequest();
  },
  getProjectDocumentDropdowns(variables) {
    return new requestBuilder({
      requestName: 'getProjectDocumentDropdowns',
      requestVariables: {
        scheduleId: 'String!',
        workspaceId: 'String!',
        projectId: 'String!',
      },
      response: `
        column
        custom
        customValues
        fixedValues
        multipleSelects
      `,
      variables,
    }).processRequest();
  },
  setProjectDocumentTag(variables) {
    return new requestBuilder({
      requestName: 'setProjectDocumentTag',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        scheduleId: 'String!',
        workspaceId: 'String!',
        projectId: 'String!',
        documentId: 'String!',
        tag: 'String',
      },
      response: PROJECT_DOCUMENT,
      variables,
    }).processRequest();
  },
  addProjectDocumentDescription(variables) {
    return new requestBuilder({
      requestName: 'addProjectDocumentDescription',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        scheduleId: 'String!',
        workspaceId: 'String!',
        projectId: 'String!',
        documentId: 'String!',
        description: 'String',
      },
      response: PROJECT_DOCUMENT,
      variables,
    }).processRequest();
  },
  removeProjectDocumentAssociatedType(variables) {
    return new requestBuilder({
      requestName: 'removeProjectDocumentAssociatedType',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        scheduleId: 'String!',
        workspaceId: 'String!',
        projectId: 'String!',
        documentId: 'String!',
        associatedRowId: 'String!',
      },
      response: PROJECT_DOCUMENT,
      variables,
    }).processRequest();
  },
  declareProjectDocument(variables) {
    const query = `
      mutation declareProjectDocument (
        $identityId: String!
        $projectId: String!
        $tableId: String!
        $tableType: TableType!
        $filename: String!
        $size: String
        $type: String!
        $workspaceId: String
        $documentType: ProjectDocumentTypes
      ) {
        response: declareProjectDocument (
          identityId: $identityId
          projectId: $projectId
          tableId: $tableId
          tableType: $tableType
          filename: $filename
          size: $size
          type: $type
          workspaceId: $workspaceId
          documentType: $documentType
        ) ${DOCUMENT}
      }`;
    return API.graphql({
      query,
      variables,
    });
  },
  deleteProjectDocuments(variables) {
    const query = `
      mutation deleteProjectDocuments (
        $tableId: String!,
        $tableType: TableType!,
        $projectId: String!,
        $documentsIds: [String!]!
        $workspaceId: String
      ) {
        response: deleteProjectDocuments (
          tableId: $tableId
          tableType: $tableType
          projectId: $projectId
          documentsIds: $documentsIds
          workspaceId: $workspaceId
        ) {
          scheduleId
        }
      }`;
    return API.graphql({
      query,
      variables,
    });
  },
};

<template>
  <v-card-title class="w-100">
    <div class="d-flex">
      <div
        :style="{background:bgColor}"
        class="circle-icon-wrapper mr-5"
        :class="{ 'pointer': showEditOfSharing }"
        @click.stop="showEditOfSharing ? setShowEditOfSharing(!showEditOfSharing) : null">
        <v-icon
          v-if="!showEditOfSharing"
          size="20"
          color="lightGrey">
          mdi-account-plus-outline
        </v-icon>
        <v-icon
          v-else
          size="20"
          color="white">
          mdi-arrow-left
        </v-icon>
      </div>
      <div
        class="align-self-center">
        <span v-if="dialogType === TYPE_COLLECTION_SHARE">{{ headerTitle }}</span>
        <span v-else>Invite members</span>
        <p
          class="body-2 ma-0">
          <slot name="subtitle" />
        </p>
      </div>
    </div>
    <div
      v-if="closedIcon"
      class="sourcery__icon-wrapper black"
      @click.stop="closeButton">
      <v-icon
        color="white"
        size="15">
        mdi-close
      </v-icon>
    </div>
  </v-card-title>
</template>
<script>
import { TYPE_COLLECTION_SHARE } from '@/constants';
import {
  mapMutations, mapState,
} from 'vuex';
export default {
  name: 'HeaderShare',
  props: {
    closedIcon: {
      type: Boolean,
      default: true,
    },
    headerTitle: {
      type: String,
      default: 'Share and add members',
    },
    bgIconColor: {
      type: String,
      default: 'blue',
    },
    dialogType: {
      type: String,
      default: TYPE_COLLECTION_SHARE,
    },
  },
  data() {
    return {
      TYPE_COLLECTION_SHARE,
    };
  },
  computed: {
    ...mapState('Collections', ['showEditOfSharing']),
    bgColor() {
      return `var(--v-${this.bgIconColor}-base)`;
    },
  },
  methods: {
    ...mapMutations('Collections', ['setShowEditOfSharing']),
    closeButton() {
      if (this.showEditOfSharing) {
        this.setShowEditOfSharing(!this.showEditOfSharing);
      } else {
        this.$emit('closeModal');
      }
    },
  },
};
</script>

import requestBuilder from '@/services/graphql/requestBuilder';
import { ASYNC_SEARCH_RESULT } from '../fragments';
import {
  SEARCH_COMMUNITY_PRODUCTS_V3, SEARCH_PRODUCTS_V3, SEARCH_PRODUCT_REF_V3,
} from '@/constants/searching/searchingAsyncV2';
export default {
  asyncCommunityProductsV3(variables) {
    return new requestBuilder({
      requestName: SEARCH_COMMUNITY_PRODUCTS_V3,
      requestVariables: {
        query: 'String',
        limit: 'Int',
        scrollID: 'String',
        workspaceId: 'String',
        filters: 'searchFilters',
        requestId: 'String',
      },
      response: ASYNC_SEARCH_RESULT,
      variables,
    }).processRequest();
  },
  asyncProductsV3(variables) {
    return new requestBuilder({
      requestName: SEARCH_PRODUCTS_V3,
      requestVariables: {
        query: 'String',
        limit: 'Int',
        scrollID: 'String',
        workspaceId: 'String',
        libraryId: 'String',
        filters: 'searchFilters',
        requestId: 'String',
        myCollections: 'Boolean',
        followedOnly: 'Boolean',
      },
      response: ASYNC_SEARCH_RESULT,
      variables,
    }).processRequest();
  },
  asyncSearchProductRefV3(variables) {
    return new requestBuilder({
      requestName: SEARCH_PRODUCT_REF_V3,
      requestVariables: {
        workspaceId: 'String!',
        projectId: 'String',
        query: 'String',
        limit: 'Int',
        scrollID: 'String',
        requestId: 'String',
      },
      response: ASYNC_SEARCH_RESULT,
      variables,
    }).processRequest();
  },
};

<template>
  <div
    class="px-0 d-flex align-center">
    <GroupModal
      :disabled="disabled"
      hide-close-icon-bottom
      hide-close-icon-top>
      <template #topHeader="{closeModal,statusHeader}">
        <HeaderShare
          :closed-icon="statusHeader"
          :header-title="showUpdatesToWorkspaceGuests ? 'Add Guests' : 'Add Members'"
          :dialog-type="dialogType"
          :bg-icon-color="!statusHeader ? 'darkGrey' : 'blue'"
          @closeModal="closeModal">
          <template #subtitle>
            {{ `${showUpdatesToWorkspaceGuests ? 'Guests' : 'Members'} can collaborate with you on this collection and can see each other.` }}
          </template>
        </HeaderShare>
        <v-card-title v-if="!statusHeader">
          <p class="body-2 ml-3 text-truncate">
            Shared with {{ listSharingName }}
          </p>
        </v-card-title>
      </template>
      <template #topContent="{closeModal, statusDialog}">
        <ShareContent
          :item="itemToShare"
          :permission-types="permissionTypes"
          :status-dialog="statusDialog"
          :disabled="!(disableActions && !item.follow)"
          :is-loading="isFetchingMembers && (memberCount == 0)"
          view-in-actions
          :members="itemMembers"
          :invite-to-text-type="COLLECTION"
          @closeModal="closeModal"
          v-on="$listeners" />
      </template>
      <template #bottomHeader="{statusHeader,closeModal}">
        <HeaderShareAdvanced
          :closed-icon="statusHeader"
          :is-disabled-close-icon="isDisabledCloseIcon"
          :theme-color="'white-theme'"
          :bg-icon-color="!statusHeader ? 'darkGrey' : 'blue'"
          @closeModal="closeModal">
          <template #subtitle>
            Share with the community and suggest that others follow this collection.
          </template>
        </HeaderShareAdvanced>
        <v-card-title v-if="!statusHeader">
          <v-row class="align-center overflow-hidden">
            <v-col cols="9">
              <p class="caption text-truncate ml-3">
                <span class="font-weight-bold"> {{ selectedItem && selectedItem.name }} </span>
                <span>{{ selectedItem && selectedItem.message }}</span>
              </p>
            </v-col>
            <v-col cols="3">
              <span
                class="color-link body-1"
                @click.stop="copyToClipBoard(selectedItem.url,'Link copied')">Copy link</span>
            </v-col>
          </v-row>
        </v-card-title>
      </template>
      <template #bottomContent="{statusDialog,closeModal}">
        <ShareContentAdvanced
          :start-subscribe="startSubscribe"
          :dialog-type="dialogType"
          :invite-to-text-type="inviteToTextType"
          :status-dialog="statusDialog"
          :item="itemToShare"
          :selected-item.sync="selectedItem"
          @onPossibleToCloseDialog="isDisabledCloseIcon = !$event"
          v-on="$listeners"
          @closeModal="closeModal" />
      </template>
    </GroupModal>
  </div>
</template>
<script>
import {
  mapGetters, mapState, mapMutations,
} from 'vuex';

import { TYPE_COLLECTION_SHARE } from '@/constants';
import { COLLECTION } from '@/constants/cores';
import { GET_PERMISSION_TYPES } from '@/constants/userPermissions';

import GroupModal from '@/components/App/AppGroupModal';
import HeaderShare from '@/components/CollectionsLibrarysRelocate/headerShare';
import HeaderShareAdvanced from '@/components/CollectionsLibrarysRelocate/HeaderShareAdvanced';
import ShareContent from '@/components/CollectionsLibrarysRelocate/ShareContent';
import ShareContentAdvanced from '@/components/CollectionsLibrarysRelocate/ShareContentAdvanced';

import { copyToClipBoard } from '@/utils';
import CollectionsApi from '@/services/graphql/collections';
import RequestAbortController from '@/utils/RequestAbortController';
export default {
  name: 'ShareAdvancedDialog',
  components: {
    GroupModal, HeaderShare, ShareContent, HeaderShareAdvanced, ShareContentAdvanced,
  },
  props: {
    startSubscribe: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inviteToTextType: {
      type: String,
      default: undefined,
    },
    permissionTypes: {
      type: Array,
      default: () => GET_PERMISSION_TYPES(['viewer', 'editor', 'creator']),
    },
    item: {
      type: Object,
      default: () => null,
    },
    itemGroup: {
      type: Object,
      default: () => null,
    },
    dialogType: {
      type: String,
      default: TYPE_COLLECTION_SHARE,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      COLLECTION,
      abortController: new RequestAbortController(),
      isDisabledCloseIcon: false,
      isFetchingMembers: false,
      nextToken: null,
      selectedItem: null,
      updatedItem: null,
    };
  },
  computed: {
    ...mapState('Collections', ['sharingDialog']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('FeatureFlags', ['showUpdatesToWorkspaceGuests']),
    itemToShare() {
      return this.updatedItem ?? this.item;
    },
    memberCount() {
      return this.itemMembers ? this.itemMembers.length : 0;
    },
    itemMembers() {
      const item = this.itemToShare;
      if (item?.members) return item.members;
      if (item?.memberSummary?.members) return item.memberSummary.members;
      return [];
    },
    listSharingName() {
      if (!this.itemMembers) return '';
      const members = this.itemMembers ?? [];
      return members.map(e => e.name).join(', ');
    },
  },
  watch: {
    item: {
      async handler() {
        this.updatedItem = null;
        this.nextToken = null;
        this.isFetchingMembers = false;
      },
    },
    sharingDialog(val) {
      if (val) {
        this.fetchMemberInfo(this.item);
      } else {
        this.abortController.abort();
        this.isFetchingMembers = false;
        this.nextToken = null;
      }
    },
  },
  created() {
    this.abortController = new RequestAbortController();
  },
  beforeDestroy() {
    this.abortController.abort();
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
    }),
    copyToClipBoard,

    shouldUpdateItemMemberInfo(item) {
      if (!item) return false;
      if (!item.members) return true;
      if (item.memberSummary) {
        if (item.memberSummary.members.length == item.memberSummary.count) {
          return false;
        }
      }
      return true;
    },
    async fetchMemberInfo(item) {
      if (!this.shouldUpdateItemMemberInfo(item)) return;

      try {
        this.abortController = new RequestAbortController();
        this.isFetchingMembers = true;

        let first = true;
        while (this.nextToken || first) {
          if (this.abortController?.aborted()) break;
          first = false;
          await this.updateItemMemberInfo(this.updatedItem ?? item);
        }
      } catch (err) {
        if (this.abortController?.aborted()) return;
        console.error('Error fetching member data for item', err);
      } finally {
        this.isFetchingMembers = false;
        this.nextToken = null;
      }
    },
    async updateItemMemberInfo(item) {
      try {
        const response = await CollectionsApi.getCollectionMembers({
          collectionId: item.id,
          collectionName: item.name,
          workspaceId: this.activeWorkspaceId,
          nextToken: this.nextToken,
        });

        const data = response.data.response;
        this.nextToken = data.nextToken;

        const updatedMembers = (item.members || []).concat(data.members || []);
        this.updatedItem = {
          ...item,
          members: updatedMembers,
        };
        this.commitMemberInfoToStore(this.updatedItem);

      } catch (err) {
        console.error('Error fetching member data for item', err);
      }
    },
    commitMemberInfoToStore(updatedItem) {
      if (!this.itemGroup) return;

      try {
        // Either the type that uses a getter, or the type that has state.
        const listingMethod = this.itemGroup.list;
        let collectionList = this.isGetterType(listingMethod)
          ? this.$store.getters[listingMethod]
          : this.$store.state.Collections[listingMethod];
        if (collectionList) {
          collectionList = collectionList.map(c => c.id === updatedItem.id ? updatedItem : c);
          this.$store.commit(`Collections/${this.itemGroup.mutationName}`, collectionList);
        }
      } catch (err) {
        console.warn('Could not commit fetched members to store.', err);
      }
    },
    isGetterType(listingMethod) {
      return listingMethod?.includes('/');
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
</style>

<template>
  <div class="published-private-collections">
    <div
      class="d-flex flex-column search-input">
      <CollectionsTableStructure
        :data="collectionsList"
        :intersect="intersect"
        :headers="filteredHeaders"
        :is-shared-page="isSharedPage"
        :group="group"
        @click-action-menu="$emit('click-action-menu', $event)"
        @onIntersect="$emit('onIntersect', $event)"
        v-on="$listeners" />
    </div>
  </div>
</template>
<script>
import CollectionsTableStructure from './CollectionsTableStructure';
import { GROUP_FOLLOWED } from '@/constants';
export default {
  name: 'CollectionsTable',
  components: {
    CollectionsTableStructure,
  },
  props: {
    hideColumnMembers: {
      type: Boolean,
      default: false,
    },
    collectionsList: {
      type: Array,
      default: () => [],
    },
    actionsConditions: {
      type: Array,
      default: () => [],
    },
    intersect: {
      type: Boolean,
      default: () => false,
    },
    group: {
      type: String,
      default: null,
    },
    isSharedPage: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      headers: [
        {
          text: 'Collection name', value: 'name',
          sortable: false,
        },
        {
          text: 'Members', align: 'right', sortable: false, value: 'members',
        },
        {
          text: 'Creator', value: 'creator', align: 'center',
          sortable: false,
        },
        {
          text: '', value: 'action', sortable: false, align: 'right',
        },
      ],
    };
  },
  computed: {
    filteredHeaders() {
      const { hideColumnMembers, headers, group } = this;
      return headers.filter(e => hideColumnMembers || (group === GROUP_FOLLOWED) ? e.text !== 'Members' : e);
    },
  },
};
</script>
<style scoped lang="scss">
.search-input {
  margin: 0px auto 30px auto;
}
</style>

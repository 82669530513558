<template>
  <div
    v-if="members"
    class="app-table-members"
    @click.stop="$emit('showShareModalAction')">
    <template v-if="totalMembers">
      <span
        v-for="(member, index) in filteredMembers"
        :key="member.id"
        :style="{
          transform:`translateX(${computePosition(index)}px)`,
          'z-index': index
        }"
        class="member white">
        <Member
          :color-icon="member.color || ''"
          :hide-avatar="hideAvatar"
          :disabled-tooltip="disabledTooltip"
          :member="member" />
      </span>
    </template>

    <div
      v-if="(totalMembers > visibleMembers ) || !totalMembers"
      class="member-length black--text fs-12 font-weight-bold white">
      <span v-if="!overflowDots">{{ memberLength }}</span>
      <span v-else>...</span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Member from '../AppTableMember';
export default {
  name: 'AppTableMembers',
  components: {
    Member,
  },
  props: {
    isFilterLastMembers: {
      type: Boolean,
      default: false,
    },
    disabledTooltip: {
      type: Boolean,
      default: false,
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
    visibleMembers: {
      type: Number,
      default: 3,
    },
    members: {
      type: [Array, String],
      default: () => [],
    },
    memberCount: {
      type: Number,
      default: -1,
    },
    overflowDots: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['avatars']),
    totalMembers() {
      return this.members.length;
    },
    memberLength() {
      // Performance tanks when fetching too many members, so we fetch a few + count (set as memberCount prop).
      const { members, totalMembers, visibleMembers } = this;
      const totalCount = this.memberCount > 0
        ? this.memberCount - visibleMembers
        : !members.length ? 0 : totalMembers - visibleMembers;
      return totalCount > 99 ? '+99' : totalCount;
    },
    totalFilteredMembers() {
      return this.filteredMembers.length;
    },
    filteredMembers() {
      const newMembers = this.members?.filter(member => member) || [];
      return this.isFilterLastMembers ? newMembers.slice( -this.visibleMembers).reverse() : newMembers.slice( 0, this.visibleMembers);
    },
    showNumberCard() {
      return this.totalMembers > this.visibleMembers;
    },
  },
  methods: {
    computePosition(index) {
      return this.showNumberCard
        ? 10 * (this.totalFilteredMembers - index)
        : 10 * (this.totalFilteredMembers - index - 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-table-members {
  position: relative;
  display: flex;
  justify-content: flex-end;

  .member {
    border-radius: 50px;
  }

  .member-length {
    border-radius: 50px;
    border: 2px solid var(--v-lightGrey-base);
    display: grid;
    height: 30px;
    place-items: center;
    width: 30px;
    z-index: 7;
  }
}
</style>

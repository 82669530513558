<template>
  <div
    class="table-container table-container__shadowed">
    <v-data-table
      item-key="id"
      must-sort
      :items="data"
      hide-default-footer
      disable-pagination
      data-test="collections_table"
      :elevation="0"
      :mobile-breakpoint="0"
      :headers="headersForRendering"
      @click:row="navigateNext($event, group)">
      <template #[`item.name`]="{ item }">
        <div
          :id="item.id"
          v-intersect.once="onIntersect"
          style="display: flex; align-items: center">
          <div
            class="project-title-wrapper">
            <v-row
              class="ma-0 flex-nowrap"
              align="center">
              <v-tooltip
                top>
                <template #activator="{ on, attrs }">
                  <div
                    class="overflow-dots__collections-wrapper"
                    v-bind="attrs"
                    v-on="on">
                    <div class="d-flex">
                      <span
                        data-test="collection_name"
                        class="overflow-dots__collections">
                        {{ item.name || item.title }}
                      </span>
                      <v-badge
                        v-show="showUnreadComments(item)"
                        class="collections-table__unread-comments-badge"
                        :content="item.commentsUnread" />
                    </div>
                  </div>
                </template>
                <span>
                  {{ item.name }}
                </span>
              </v-tooltip>
            </v-row>
          </div>
        </div>
      </template>
      <template #[`item.members`]="{ item }">
        <Members
          :members="getCollItemMembers(item)"
          :member-count="getCollItemMemberCount(item)" />
      </template>
      <template #[`item.creator`]="{ item }">
        <Creator :member="item.creator" />
      </template>
      <template #[`item.action`]="{ item }">
        <v-icon
          v-if="!isSharedPage"
          class="brightness-icon"
          medium
          @click.stop="$emit('click-action-menu', {
            item,
            event: $event,
          })"
          v-on="$listeners">
          mdi-dots-vertical
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Members from '@/components/App/AppTable/AppTableMembers';
import Creator from '@/components/App/AppTable/AppTableMember';
import NavigateToCollection from '@/mixins/NavigateToCollection';
import TableHeadersToMobile from '@/mixins/TableHeadersToMobile';
import { WORKSPACE } from '@/constants/cores';
import { GROUP_SHARED } from '@/constants';
export default {
  name: 'CollectionsTableStructure',
  components: {
    Members,
    Creator,
  },
  mixins: [NavigateToCollection, TableHeadersToMobile],
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    intersect: {
      type: Boolean,
      default: () => false,
    },
    tableType: {
      type: String,
      default: null,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      default: null,
    },
    isSharedPage: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    // for TableHeadersToMobile mixin
    MOBILE_COLS_TO_RENDERING: [
      'name',
      'action',
    ],
  }),
  methods: {
    onIntersect(entries, observer, isIntersecting) {
      if (!this.intersect) {
        return;
      }
      this.$emit('onIntersect', {
        entries, observer, isIntersecting,
      });
    },
    showUnreadComments({ commentsUnread, collectionGroup }) {
      return (collectionGroup == WORKSPACE || collectionGroup == GROUP_SHARED) && commentsUnread;
    },
    setAppropriateFormat(data) {
      // Don't know why but moment doesn't work with timestamp string only number
      return this.$moment(Number(data)).format('YYYY-MM-DD HH:mm');
    },
    getCollItemMembers(item) {
      if (item.memberSummary) {
        return item.memberSummary.members;
      } else {
        return item.members;
      }
    },
    getCollItemMemberCount(item) {
      if (item.memberSummary) {
        return item.memberSummary.memberCount;
      } else {
        return item.members ? item.members.length : 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .table-container {
    height: 100%;
    overflow: auto;
  }
  .collections-table__unread-comments-badge {
    ::v-deep .v-badge__badge {
      bottom: 12px !important;
    }
  }
</style>

<template>
  <div
    v-if="!isRelativeTime"
    v-safe-html="date" />
  <div v-else>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on">
          {{ relativeTime }}
        </span>
      </template>
      <span v-safe-html="date" />
    </v-tooltip>
  </div>
</template>
<script>
import { isPassedDeadlineDay } from '@/utils';
export default {
  name: 'DocumentCellDate',
  props: {
    dateUploaded: {
      type: String,
      default: '',
    },
  },
  computed: {
    relativeTime() {
      const moment = this.$moment(this.dateUploaded);
      return moment.fromNow();
    },
    isRelativeTime() {
      return !isPassedDeadlineDay(this.dateUploaded, 7);
    },
    date() {
      const hourMinute = this.$moment(this.dateUploaded).format('H:mm') || '';
      const fullDate = this.$moment(this.dateUploaded).format('YYYY-MM-DD') || '';
      return `${fullDate} <br>${hourMinute}`;
    },
  },
};
</script>
<style scoped>
</style>

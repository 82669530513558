var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[(!_vm.editMode && !_vm.isExpandedMode)?_c('div',{staticClass:"url-wrapper d-flex justify-center align-center",staticStyle:{"height":"100%"}},_vm._l((_vm.value),function(url,index){return _c('a',{key:index,attrs:{"href":_vm.linkToOpening(url),"target":"_blank"}},[_vm._v(" "+_vm._s(url)+" "),_c('br')])}),0):[_c('div',{staticClass:"col-12 px-0",class:[
        { 'editing-container' : !_vm.isExpandedMode },
        { 'py-0' : _vm.isExpandedMode }
      ]},[_c('div',{staticClass:"d-flex flex-row align-center",style:({ height: '100%', position: _vm.isExpandedMode ? 'relative' : ''})},[_c('v-textarea',{ref:"textareaRef",staticClass:"border-outline sourcery__filled-grey",class:{ 'textarea-scraper-padding' : _vm.isExpandedMode },attrs:{"auto-grow":_vm.isExpandedMode,"autofocus":!_vm.isExpandedMode,"disabled":!_vm.editMode,"rows":_vm.isExpandedMode ? 1 : 6,"hint":_vm.hintMessage,"hide-details":!_vm.urlErrorMessage.length && (!_vm.hintMessage || !_vm.isFocus),"clearable":!_vm.isExpandedMode,"error-messages":_vm.urlErrorMessage,"filled":""},on:{"focus":function($event){_vm.isFocus = true},"blur":_vm.update},model:{value:(_vm.urlObj.newValue),callback:function ($$v) {_vm.$set(_vm.urlObj, "newValue", $$v)},expression:"urlObj.newValue"}}),(_vm.showScrapeButton)?_c('div',{class:['scraper', _vm.isScraperDisabled ? 'scraper-disabled' : 'scraper-enabled']},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"height":"24","width":"24","src":require("@/assets/icons/scraper-icon.svg")},on:{"click":_vm.scrapeUrlForData}},'img',attrs,false),on))]}}],null,false,3529459301)},[_c('span',[_vm._v(_vm._s(_vm.isScrapingProductData ? 'Importing...' : ( _vm.isScraperDisabled ? 'Enter valid Link to Import' : 'Import this Product')))])])],1):_vm._e()],1),(_vm.showLinkOpenInNewTab)?_c('div',{staticClass:"col-12 pl-2 pt-0 pb-0 pr-0 d-flex justify-start"},[_c('v-menu',{attrs:{"nudge-bottom":22,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('a',_vm._g({staticClass:"pointer mt-2",attrs:{"target":"_blank","href":null}},on),[_vm._v(" Open link(s) in new tab(s) ")])]}}],null,false,2497649868)},[_c('v-list',_vm._l((_vm.value),function(url,index){return _c('a',{key:index,staticClass:"px-2 overflow-link",style:({'max-width': _vm.textareaExpandedWidth && ((_vm.textareaExpandedWidth - 14) + "px")}),attrs:{"href":_vm.linkToOpening(url),"target":"_blank"}},[_vm._v(" "+_vm._s(url)+" "),_c('br')])}),0)],1)],1):_vm._e()])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }
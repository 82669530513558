import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['showSpinner']),
  },
  methods: {
    async onPaste(e) {
      if (!this.showSpinner) {
        const { clipboardData } = e;
        if (clipboardData.files.length) {
          this.spinner(true);
          const files = Array.from(clipboardData.files);
          await this.$nextTick();
          await this.$refs.upload.add(files);
          this.spinner(false);
        }
      }
    },
  },
};

import { mapActions } from 'vuex';
export default {
  methods: {
    ...mapActions(['setViewMode']),
    toggleViewMode({ criteria, value }) {
      this.setViewMode({
        [criteria]: value,
      });
    },
  },
};

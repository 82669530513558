<template>
  <div>
    <app-dialog
      v-model="isModalOpen"
      :value.sync="isModalOpen"
      width="1450px"
      content-class="v-dialog__form attachments-documents-wrapper">
      <project-list-documents
        :id-file-upload="idFileUpload"
        :document-type="documentType"
        :can-work-with-project-documents="canModifyProjectDocuments"
        :is-modal-open="isModalOpen"
        @closeDialog="closeDialog" />
    </app-dialog>
    <app-icon-btn
      v-if="!hideBtn"
      :access="canModifyProjectDocuments"
      :is-icon="false"
      :icon-name="DocumentIcon"
      text="Documents"
      v-bind="sizing"
      wrapper-class="d-flex align-center gap-3"
      show-tooltip
      right
      @click="openDocument">
      <span
        v-if="$vuetify.breakpoint.mdAndDown"
        class="fs-16">Documents</span>
    </app-icon-btn>
  </div>
</template>
<script>
import {
  mapGetters, mapState,
} from 'vuex';

import { TYPE_DOCUMENTS } from '@/constants/projectDocument';

import ProjectListDocuments from '@/components/CollectionDetails/ProjectListDocuments';

import DocumentIcon from '@/assets/icons/document.svg';
export default {
  name: 'ProjectDetailsDocuments',
  components: {
    ProjectListDocuments,
  },
  props: {
    idFileUpload: {
      type: String,
      default: '',
    },
    hideBtn: {
      type: Boolean,
      default: false,
    },
    documentType: {
      type: String,
      default: TYPE_DOCUMENTS[0].type,
    },
    sizing: {
      type: Object,
      default: () => ({
        'icon-height': '30',
        'icon-width': '30',
      }),
    },
  },
  data: function () {
    return {
      DocumentIcon,
      isModalOpen: false,
    };
  },
  computed: {
    ...mapState(['role']),
    ...mapGetters('UserRoles', ['canModifyProjectDocuments']),
  },
  watch: {
    isModalOpen(val) {
      if (!val) {
        this.$emit('getDocuments');
        this.$emit('clearAllItems');
      }
    },
  },
  methods: {
    openDocument() {
      if (!this.canModifyProjectDocuments.allowed) {
        return false;
      }
      this.openDialog();
    },
    openDialog() {
      this.isModalOpen = true;
    },
    closeDialog() {
      this.isModalOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .collections-photos-icon {
  display: flex;
  cursor: pointer;
  align-items: center;
  p {
    margin-bottom: 0px !important;
  }
}
.v-card__text {
  max-height: 300px;
  overflow: auto;
}
</style>

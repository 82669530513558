import {
  mapActions, mapMutations,
} from 'vuex';
import CollectionsAPI from '@/services/graphql/collections';
export default {
  data() {
    return {
      subscribe: null,
    };
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      setSharingOption: 'Collections/setCurrentCollectionSharingOption',
      setActiveCollection: 'Collections/setActiveCollection',
    }),
    ...mapActions({
      handlerError: 'handleError',
      publishCollectionForCommunity: 'Collections/publishCollectionForCommunity',
      unpublishCollectionFromCommunity: 'Collections/unpublishCollectionFromCommunity',
    }),
    async subscribeSharing(startSubscribe) {
      try {
        this.subscribe = await CollectionsAPI.subscribeUpdateCollectionSharingOptions({
          id: this.item.id,
        }).subscribe({
          next: async ({ value }) => {
            const options = value?.data?.response;
            this.setSharingOption(options);
            let item = {
              ...this.item,
            };
            const type = options?.type;
            if (type === 'publish' && !item.publish) {
              item = {
                ...item, publish: true,
              };
              await this.publishCollectionForCommunity({
                item,
                collectionId: item.id,
                updateActiveListingElement: this.$route.name !== 'collections',
              });
            } else if (['unlistedPublish', 'private'].includes(type) && item.publish) {
              item = {
                ...item, publish: false,
              };
              await this.unpublishCollectionFromCommunity({
                collectionId: item.id,
                item,
                updateActiveListingElement: this.$route.name !== 'collections',
              });
            }
            startSubscribe && this.setActiveCollection(item);
          },
        });
      } catch (err) {
        this.handlerError(err);
      }
    },
  },
};

<template>
  <div class="product-cell-form-aside-skeleton px-3 mt-6">
    <div
      v-for="n in 2"
      :key="n"
      class="product-cell-form-aside-skeleton-item">
      <v-skeleton-loader
        class="item-header"
        type="text" />
      <v-skeleton-loader
        class="item-text-field"
        type="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCellFormAsideSkeleton',
};
</script>

<style scoped lang="scss">
 ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__text {
      height: 100%;
    }
  }
  ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__image {
      height: 100%;
    }
  }
  .product-cell-form-aside-skeleton {
    max-width: 85%;
  }
  .product-cell-form-aside-skeleton-item {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .item-header {
    width: 50%;
    height: 36px;
    max-width: 150px;
  }
  .item-text-field {
    height: 8rem;
    width: 100%;
  }
</style>
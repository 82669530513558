<template>
  <BaseLayout v-bind="$attrs">
    <template
      #list="{
      filterHeadersByFlexItem,
      isRequiredField,
      canBeRendered,
      isDialogCollection,
      collectionId,
      renderClass,
      searchMode,
      showPensil,
      view,
      disabledEditMode,
      isAsideForm,
      isLoading,
    }">
      <template
        v-for="(headerItem, i) in filterHeadersByFlexItem">
        <div
          v-if="canBeRendered(headerItem , item) && !(isAsideHeader(headerItem.text) && !isAsideForm)"
          :key="`${headerItem.value}__${i}`"
          :class="['collection-products-list', isTopSeparator(headerItem.text)]">
          <ProductCell
            :class="[renderClass(headerItem)]"
            :collection-id="collectionId"
            :disabled-edit-mode="isHeaderDisabledForEditing(disabledEditMode, headerItem)"
            :expanded-row="!showPensil(headerItem.column.type)"
            :header="headerItem"
            :is-dialog-collection="isDialogCollection"
            :is-loading="isHeaderLoading(headerItem.text)"
            :is-required="isRequiredField(headerItem.id)"
            :item="item"
            :mapped-dropdowns-of-view="mappedDropdownsOfView"
            :search-mode="searchMode"
            :should-expand-height="isAsideHeader(headerItem.text)"
            :style="{ justifyContent: headerItem.align === 'right' ? 'flex-end' : 'inherit' }"
            :view="view"
            class="pa-0"
            open-link-in-new-tab
            @updateProductField="$emit('updateProductField', $event)" />
        </div>
      </template>
      <template v-if="isLoading">
        <ProductCellFormSkeleton
          v-if="!isAsideForm"
          class="mt-8 ml-4" />
        <ProductCellFormAsideSkeleton
          v-else
          class="mt-2 ml-4" />
      </template>
    </template>
  </BaseLayout>
</template>
<script>
import ProductCell from '@/components/Product/ProductCell';
import BaseLayout from './ProductCellCollectionsFormListBaseLayout';
import ProductCellFormSkeleton from '@/components/Product/ProductCell/ProductCellFormSkeleton';
import ProductCellFormAsideSkeleton from '@/components/Product/ProductCell/ProductCellFormAsideSkeleton';
import productHeaders from '@/constants/productHeaders';
import { isAsideHeaderName } from '@/utils/productHeaders';
export default {
  name: 'ProductCellCollectionsFormList',
  components: {
    ProductCell,
    ProductCellFormSkeleton,
    ProductCellFormAsideSkeleton,
    BaseLayout,
  },
  props: {
    item: {
      type: Object,
      default: () => ({
      }),
    },
    mappedDropdownsOfView: {
      type: Object,
      default: () => {
      },
    },
    loadingHeaders: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isAsideHeader(headerName) {
      return isAsideHeaderName(headerName);
    },
    isHeaderLoading(headerName) {
      return this.loadingHeaders?.includes(headerName);
    },
    isHeaderDisabledForEditing(disabledEditMode, headerName) {
      return disabledEditMode || this.isHeaderLoading(headerName);
    },
    isTopSeparator(headerName) {
      return headerName === productHeaders.CHOOSE_COLLECTION
        ? 'lightGrey' : '';
    },
  },
};
</script>
<style lang="scss" scoped>
.hide-when-aside-shows {
  display: none;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    // The aside items show at the bottom of the original now.
    display: block;
  }
}
.collection-products-list {
  padding: 10px 30px;
}
.field-error {
  border-bottom: 2px solid red;
}
</style>

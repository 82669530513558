import { mapMutations } from 'vuex';
export default {
  methods: {
    ...mapMutations(['spinner']),
    onPdfProgress(progress) {
      if (progress < 1) {
        this.spinner(true);
        return;
      }
      this.spinner(false);
    },
  },
};

<template>
  <div class="d-flex flex-column px-7 py-5">
    <slot name="rowCell" />
  </div>
</template>
<script>
export default {
  name: 'AppFormRow',
  props: {
    isAsideForm: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

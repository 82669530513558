<template>
  <div>
    <v-text-field
      :placeholder="placeholderText"
      color="black"
      class="border-outline"
      dense
      flat
      hide-details
      readonly
      solo
      @click="openSearch" />
    <CreateProductSearchDialog
      :search-open="searchOpen"
      v-bind="$attrs"
      v-on="$listeners"
      @searchDialogClosed="closeSearch" />
  </div>
</template>
<script>
import CreateProductSearchDialog from './CreateProductSearchDialog';
import { mapMutations } from 'vuex';
export default {
  name: 'CreateProductSearch',
  components: {
    CreateProductSearchDialog,
  },
  data() {
    return {
      searchOpen: false,
      placeholderText: 'Click here to search for a Product in this Workspace.',
    };
  },
  destroyed() {
    this.setProductModalInvisible(false);
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapMutations('Collections', ['setProductModalInvisible']),
    openSearch() {
      this.searchOpen = true;
      this.setProductModalInvisible(true);
    },
    closeSearch() {
      this.searchOpen = false;
      this.setProductModalInvisible(false);
    },
  },
};
</script>

import ProductHeaders from '@/constants/productHeaders';

function sortProductDialogSchemaHeaders(schema) {
  if (!Array.isArray(schema)) {
    console.warn('Passed in non-array schema for sorting. Returning as is!');
    return schema;
  }

  const sortedSchema = [...schema];
  sortedSchema.sort(sortProductDialogHeaders);
  sortedSchema.sort(sortAsideHeadersLast);

  return sortedSchema;
}

/*********************************************
 * Part of "Move Tips and Tricks/Product Notes aside"
 * https://app.asana.com/0/1205146388025688/1205944872379948/f
 ********************************************/

const { TIPS_AND_TRICKS, PRODUCT_NOTES } = ProductHeaders;

function isAsideHeader(header) {
  return [TIPS_AND_TRICKS, PRODUCT_NOTES].indexOf(header?.text) >= 0;
}

function isAsideHeaderName(headerName) {
  return [TIPS_AND_TRICKS, PRODUCT_NOTES].indexOf(headerName) >= 0;
}

function sortAsideHeadersLast(headerA, headerB) {
  let aAside = isAsideHeaderName(headerA?.text);
  let bAside = isAsideHeaderName(headerB?.text);
  if (aAside && !bAside) return 1;
  if (!aAside && bAside) return -1;
  return 0;
}

// https://app.asana.com/0/1205146388025688/1207898211310102
// - Revision - Pull Website, Model, Manufacturer up - but just for the diaog.
const { WEBSITE_LINK, MODEL, MANUFACTURER } = ProductHeaders;
const HEADER_RANKINGS = {
  [WEBSITE_LINK]: 0,
  [MANUFACTURER]: 1,
  [MODEL]: 2,
};

function sortProductDialogHeaders(headerA, headerB) {
  let aRanking = HEADER_RANKINGS[headerA?.text] ?? 99;
  let bRanking = HEADER_RANKINGS[headerB?.text] ?? 99;
  return aRanking - bRanking;
}

export {
  isAsideHeader,
  isAsideHeaderName,
  sortAsideHeadersLast,
  sortProductDialogSchemaHeaders,
};
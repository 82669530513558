<template>
  <div class="listing-image">
    <template v-if="!logoClass">
      <!-- images -->
      <div
        v-if="backgroundFilesLength"
        class="images">
        <!-- logo -->
        <div
          v-if="renderedLogo"
          class="back-image-item back-image-item--logo-wrapper">
          <img
            v-lazy="renderedLogo.url"
            class="logo-image"
            alt="logo">
        </div>

        <!-- actual image -->
        <div
          v-if="showCollage && renderedBgImage"
          :data-key="renderedBgImage.key"
          class="back-image-item"
          style="height: 100%">
          <img
            v-lazy="renderedBgImage.url"
            alt="default_Image_item"
            @error="onError(renderedBgImage.id)">
        </div>
      </div>

      <!-- placeholder image -->
      <div
        v-else
        class="images">
        <v-img
          src="@/assets/icons/placeholder.svg"
          alt="logo"
          width="100%"
          height="auto" />
      </div>
    </template>
    <div
      v-else
      :class="logoClass"
      class="images" />
  </div>
</template>
<script>
import { Storage } from 'aws-amplify';
import {
  mapActions, mapGetters,
} from 'vuex';

import { parseStorageKey } from '@/utils';

import fileAttempts from '@/mixins/fileAttempts';

export default {
  name: 'ListingImage',
  mixins: [fileAttempts],
  props: {
    pictures: {
      type: Array,
      default: () => [],
    },
    collection: {
      type: Object,
      default: () => null,
    },
    watchForPictures: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      backgroundFiles: [],
      attempts: {
      },
    };
  },
  computed: {
    ...mapGetters('ManageFiles', ['getFileByKey', 'getFullFileByUrl']),
    backgroundFilesLength() {
      return this.backgroundFiles.length;
    },
    childVerifiedWs() {
      return this.collection?.childVerified;
    },
    createdProUser() {
      return this.collection?.createdProUser;
    },
    isVerified() {
      return this.collection?.isVerified;
    },
    logoClass() {
      const { collection } = this;
      if (!collection) {
        return false;
      }
      const { kind, id } = collection;
      if (id === 'community') {
        return 'community-background';
      } else if (kind === 'default' && !this.collection.logo) {
        return 'my-library-background ';
      } return false;
    },
    renderedBgImage() {
      return this.backgroundFiles.find(item => !item?.isLogo);
    },
    renderedLogo() {
      return this.backgroundFiles.find(item => item?.isLogo && item.url);
    },
    showCollage() {
      return this.createdProUser || !this.renderedLogo;
    },
    verifiedPageId() {
      return this.collection?.verifiedPageId;
    },
    verifiedPageName() {
      return this.collection?.verifiedPageName;
    },
  },
  watch: {
    pictures: {
      handler(val, oldVal) {
        if (!this.lodash.isEqual(val, oldVal)) {
          this.backgroundFiles = [];
          this.getUrls();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('ManageFiles', ['parseFile', 'removeFilesFromCache']),
    getUrls(replace = false) {
      this.pictures.map(el => {
        if (el?.isLogo) {
          this.getLogos(el, replace);
        } else if (el?.attachment?.key) {
          this.getImages(el.attachment, replace);
        }
      });
    },
    onError(fileId) {
      const foundPicture = this.pictures.find((picture) => {
        if (picture?.isLogo) {
          return picture.key == fileId;
        }
        return picture?.attachment.id == fileId;
      });
      let fileKey = foundPicture?.isLogo ? foundPicture.key : foundPicture?.attachment.key;
      const { key } = parseStorageKey(fileKey);
      this.addAttempts(key);
      if (!this.haveEnoughAttempts(key)) {
        return;
      }
      this.removeFilesFromCache({
        keys: [key],
      });
      this.backgroundFiles = this.backgroundFiles.map(file => {
        if (file.key === key) {
          return {
            url: '',
            ...file,
          };
        }
        return file;
      });
      this.getUrls(true);
    },
    async getLogos({ key, ...rest }, replace = false) {
      try {
        const url = await Storage.get(key, {
          level: 'public',
        });
        const newImage = {
          ...rest,
          url,
          key,
        };
        if (!replace) {
          this.backgroundFiles = [
            ...this.backgroundFiles,
            newImage,
          ];
          return;
        }
        this.backgroundFiles = this.backgroundFiles.map(file => {
          return file.key == key ? newImage : file;
        });
      } catch (err) {
        console.log('err', err);
      }
    },
    async getImages({ key, ...rest }, replace = false) {
      const url = await this.getImagesUrl(key, replace);
      const newImage = {
        ...rest,
        url,
      };
      if (!replace) {
        this.backgroundFiles = [
          ...this.backgroundFiles,
          newImage,
        ];
        return;
      }
      this.backgroundFiles = this.backgroundFiles.map(file => {
        return file.id == rest.id ? newImage : file;
      });
    },
    async getImagesUrl(initKey) {
      let url = null;
      if (initKey) {
        const { identityId, key } = parseStorageKey(initKey);
        url = await Storage.get(
          key, {
            level: 'protected',
            identityId,
          });
      }
      return url;
    },
  },
};
</script>
<style lang="scss" scoped>
.listing-image {
  height: 100%;
  width: 100%;
  position: relative;

  .verified-check {
    position: absolute;
    z-index: 4;
    right: 0;
    top: 0;
    transform: translate(25%, -25%);
  }

  .images {
    border-radius: 15px;
    cursor: pointer;
    display: grid;
    align-items: center;
    height: 100%;

    .back-image-item {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      span {
        height: 100%;
        canvas {
          height: 100%!important;
          width: auto!important;
        }
      }
    }
    .back-image-item {
      display: grid;
      transition: 0.5s;
      z-index: 1;

      &--logo-wrapper {
        position: absolute;
        inset: 0;
        z-index: 2;

        .logo-image {
          object-fit: contain;
        }
      }
    }
  }

  .images:hover {
    transition: 0.5s;
    background-size: 130%;

    .back-image-item:hover {
      transition: 0.5s;
      transform: scale(1.2);
    }
  }
}
</style>

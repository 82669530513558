<template>
  <div>
    <div class="content-name">
      {{ name }}
    </div>
    <div>{{ size }}</div>
  </div>
</template>
<script>
import { convertKbToMb } from '@/utils';
export default {
  name: 'DocumentCellFileNameSize',
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    name() {
      return this.item.filename || '';
    },
    size() {
      return convertKbToMb(this.item.size, 'MB');
    },
  },
};
</script>
<style scoped>
.content-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  scroll-behavior: smooth;
  word-break: break-word;
  width: 100%;
}
</style>

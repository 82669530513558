var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-0 d-flex align-center"},[_c('GroupModal',{attrs:{"disabled":_vm.disabled,"hide-close-icon-bottom":"","hide-close-icon-top":""},scopedSlots:_vm._u([{key:"topHeader",fn:function(ref){
var closeModal = ref.closeModal;
var statusHeader = ref.statusHeader;
return [_c('HeaderShare',{attrs:{"closed-icon":statusHeader,"header-title":_vm.showUpdatesToWorkspaceGuests ? 'Add Guests' : 'Add Members',"dialog-type":_vm.dialogType,"bg-icon-color":!statusHeader ? 'darkGrey' : 'blue'},on:{"closeModal":closeModal},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(" "+_vm._s(((_vm.showUpdatesToWorkspaceGuests ? 'Guests' : 'Members') + " can collaborate with you on this collection and can see each other."))+" ")]},proxy:true}],null,true)}),(!statusHeader)?_c('v-card-title',[_c('p',{staticClass:"body-2 ml-3 text-truncate"},[_vm._v(" Shared with "+_vm._s(_vm.listSharingName)+" ")])]):_vm._e()]}},{key:"topContent",fn:function(ref){
var closeModal = ref.closeModal;
var statusDialog = ref.statusDialog;
return [_c('ShareContent',_vm._g({attrs:{"item":_vm.itemToShare,"permission-types":_vm.permissionTypes,"status-dialog":statusDialog,"disabled":!(_vm.disableActions && !_vm.item.follow),"is-loading":_vm.isFetchingMembers && (_vm.memberCount == 0),"view-in-actions":"","members":_vm.itemMembers,"invite-to-text-type":_vm.COLLECTION},on:{"closeModal":closeModal}},_vm.$listeners))]}},{key:"bottomHeader",fn:function(ref){
var statusHeader = ref.statusHeader;
var closeModal = ref.closeModal;
return [_c('HeaderShareAdvanced',{attrs:{"closed-icon":statusHeader,"is-disabled-close-icon":_vm.isDisabledCloseIcon,"theme-color":'white-theme',"bg-icon-color":!statusHeader ? 'darkGrey' : 'blue'},on:{"closeModal":closeModal},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(" Share with the community and suggest that others follow this collection. ")]},proxy:true}],null,true)}),(!statusHeader)?_c('v-card-title',[_c('v-row',{staticClass:"align-center overflow-hidden"},[_c('v-col',{attrs:{"cols":"9"}},[_c('p',{staticClass:"caption text-truncate ml-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.selectedItem && _vm.selectedItem.name)+" ")]),_c('span',[_vm._v(_vm._s(_vm.selectedItem && _vm.selectedItem.message))])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticClass:"color-link body-1",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipBoard(_vm.selectedItem.url,'Link copied')}}},[_vm._v("Copy link")])])],1)],1):_vm._e()]}},{key:"bottomContent",fn:function(ref){
var statusDialog = ref.statusDialog;
var closeModal = ref.closeModal;
return [_c('ShareContentAdvanced',_vm._g({attrs:{"start-subscribe":_vm.startSubscribe,"dialog-type":_vm.dialogType,"invite-to-text-type":_vm.inviteToTextType,"status-dialog":statusDialog,"item":_vm.itemToShare,"selected-item":_vm.selectedItem},on:{"update:selectedItem":function($event){_vm.selectedItem=$event},"update:selected-item":function($event){_vm.selectedItem=$event},"onPossibleToCloseDialog":function($event){_vm.isDisabledCloseIcon = !$event},"closeModal":closeModal}},_vm.$listeners))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
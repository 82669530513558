<template>
  <fragment>
    <v-text-field
      class="size-definer__field"
      :label="label"
      :placeholder="label"
      outlined
      min="100"
      dense
      type="number"
      :value="size"
      @input="onUpdateField('size', $event)" />
    <v-switch
      class="size-definer__switch mt-0 ml-2 mr-2"
      @change="onUpdateField('unit', $event)">
      <template #prepend>
        <v-label>%</v-label>
      </template>
      <template #append>
        <v-label>px</v-label>
      </template>
    </v-switch>
    <v-tooltip
      right
      class="ml-2">
      <template #activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          size="20"
          color="rgba(0, 0, 0, 0.45)"
          v-on="on">
          {{ icon }}
        </v-icon>
      </template>
      <span>{{ description }}</span>
    </v-tooltip>
  </fragment>
</template>
<script>
export default {
  name: 'SizeDefiner',
  props: {
    value: {
      type: Object,
      default: () => ({
        size: 100,
        unit: false,
      }),
    },
    icon: {
      type: String,
      default: 'mdi-information',
    },
    description: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      size: 100,
      unit: false,
    };
  },
  created() {
    const {
      size = 100,
      unit = false,
    } = this.value ?? {
    };
    this.size = size;
    this.unit = unit;
  },
  methods: {
    onUpdateField(field, value) {
      const isFieldExist = field && this.hasOwnProperty(field);
      if (isFieldExist) {
        this[field] = value;
        this.onInput();
      }
    },
    onInput() {
      const { size, unit } = this;
      this.$emit('input', {
        size,
        unit,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.size-definer {
  &__field {
    width: 100%;
    max-width: 140px;
    height: 40px;
  }
  &__switch {
    height: 35px;
  }
}
</style>

import { parseStorageKey } from '@/utils';
import { Storage } from 'aws-amplify';
import { cloneDeep } from 'lodash';
export default {
  methods: {
    async getStorage(keyAttachment) {
      const { identityId, key } = parseStorageKey(keyAttachment);
      return await Storage.get(
        key, {
          level: 'protected',
          identityId,
        }
      );
    },
    async getImageFromStorage(items) {
      const cloneItems = cloneDeep(items);
      const newItems = await Promise.all(cloneItems.map(async (v) => {
        try {
          const { key, thumbnails } = v;
          if (key) {
            v.url = await this.getStorage(key);
          }
          if (thumbnails) {
            for (const property in thumbnails) {
              const thumbnail = thumbnails[property];
              if (thumbnail) {
                thumbnails[property].url = await this.getStorage(thumbnail.key);
              }
            }
          }
        } catch (err) {
          console.log('getImageFromStorage err', err);
        }
        return v;
      }));
      return newItems;
    },
  },
};

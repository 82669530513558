<template>
  <div
    :class="{
      'lightGrey h-100': isSearching,
    }">
    <SearchFilter
      :placeholder="searchPlaceholder"
      :query-filters="filteredQuery"
      :update-from-props="updateSearchFromProps"
      @search="onFilterSearch" />
    <div class="sourcery__container">
      <template v-if="!showListingSkeleton">
        <CollectionsSearchGroups
          v-if="!activeQueryCriteria"
          :items="itemsToRendering"
          @switchView="searchNewSet"
          @handleItemClick="handleItemClick" />
        <CollectionsSearchExpandedRows
          v-if="appropriateItemsLength"
          :is-products-criteria="isProductsCriteria"
          :is-store-product-preload="isStoreProductPreload"
          :title="activeGroupTitle"
          :criteria="activeQueryCriteria"
          :title-icon="activeTitleIcon"
          :items="appropriateItemsToRendering"
          :is-loading-items="isLoadingAppropriateItems"
          @switchView="searchNewSet"
          @handleItemClick="handleItemClick({
            ...$event,
            criteria: activeQueryCriteria,
          })"
          @openUpdateWindow="setReadCollectionViewHandle"
          @onIntersect="onIntersect" />
      </template>
      <CollectionsListingSectionSkeleton v-else />
    </div>
  </div>
</template>
<script>
import {
  debounce, isEmpty, isEqual,
} from 'lodash';
import SearchingProps from '@/mixins/SearchingProps';
import AsyncSearching from '@/mixins/AsyncSearching';
import {
  DEBOUNCE_TIME_FOR_SEARCHING, ROUTE_WORKSPACE_PAGE,
} from '@/constants';
import {
  mapMutations, mapState, mapActions, mapGetters,
} from 'vuex';
import {
  PRODUCT_AND_COLLECTION_FILTERS, MANUFACTURER, VERIFIED, WORKSPACE_PAGE_FILTERS,
} from '@/constants/searching/searchingFilters';
import { v4 as uuid } from 'uuid';
import {
  SEARCH_V2_QUERIES,
  PRODUCTS_CRITERIA,
  COLLECTIONS_CRITERIA,
  WORKSPACE_PAGES_CRITERIA,
} from '@/constants/searching/searchingAsyncV2';
import { ASYNC_SEARCH_WORKSPACE_PAGES } from '@/constants/userPermissions';
import XRay from '@/utils/xRay/XRay';
const xRay = new XRay();
export default {
  name: 'CollectionsSearch',
  components: {
    CollectionsSearchExpandedRows: () => import('./CollectionsSearchExpandedRows'),
    SearchFilter: () => import('@/components/SearchFilter'),
    CollectionsSearchGroups: () => import('./CollectionsSearchGroups'),
    CollectionsListingSectionSkeleton: () => import('@/components/App/AppSkeleton/Collections/CollectionListingSectionSkeleton'),
  },
  mixins: [
    SearchingProps,
    AsyncSearching,
  ],
  props: {
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
    customSearchKeyword: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      updateSearchFromProps: true,
      lastIntersectedId: null,
      scrollID: null,
      activeQueryCriteria: null,
      tempAsyncItems: {
      },
      filtersList: PRODUCT_AND_COLLECTION_FILTERS,
      filteredQuery: {
      },
    };
  },
  computed: {
    ...mapState(['showSpinner', 'isWorkspaceOnlySearch']),
    ...mapState('Collections', ['isLoadingSearchData', 'itemsFromCollectionSearch']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('FeatureFlags', ['useSkeleton', 'useLazyLoading']),
    renderMapping: {
      get() {
        return this.itemsFromCollectionSearch;
      },
      set(val) {
        this.setItemsFromCollectionSearch(val);
      },
    },
    appropriateItems: {
      get() {
        const { dataArr: arr } = this.activeGroupObj ?? {
        };
        if (!arr) return [];
        return arr;
      },
      set(data) {
        this.setSearchAllData({
          data: {
            [this.activeQueryCriteria]: {
              data,
            },
          },
        });
      },
    },
    wsPagesRenderingObject() {
      return this.renderMapping.find(({ criteria }) => criteria === WORKSPACE_PAGES_CRITERIA);
    },
    itemsToRendering() {
      return this.renderMapping.reduce((result, item) => {
        const { criteria, dataArr } = item ?? {
        };
        if (!dataArr || !dataArr.length) return result;
        return [
          ...result,
          {
            ...item,
            dataArr: this.setMappedList({
              list: dataArr,
              criteria,
            }),
          },
        ];
      }, []);
    },
    isExistQueryRequest() {
      return !isEmpty(this.filteredQuery) || !isEmpty(this.$route.query);
    },
    isFilterKeyword() {
      const { query } = this.variablesForSearching ?? {
      };
      return !!query;
    },
    isSearching() {
      return this.isFilterKeyword || Object.keys(this.variablesForSearching.filters).length;
    },
    hasVerifiedFilter() {
      const { filters } = this.variablesForSearching ?? {
      };
      return filters && filters.isVerified !== undefined;
    },
    manufacturerFilter() {
      const { filters } = this.variablesForSearching ?? {
      };
      const { [MANUFACTURER]: manufacturer } = filters ?? {
      };
      return manufacturer;
    },
    manufacturerFilterQuery() {
      const { manufacturerFilter: manufacturer } = this;
      const { query } = this.variablesForSearching ?? {
      };
      if (!manufacturer) return query;
      const arr = Array.isArray(manufacturer) ? manufacturer : [manufacturer];
      return [...arr, ...(query ? [query] : [])].join(' ');
    },
    isAllowWsPagesSearching() {
      const { manufacturerFilterQuery: query, isFilterKeyword } = this;
      return isFilterKeyword || query || this.hasVerifiedFilter;
    },
    variablesForSearching() {
      const { activeWorkspaceId: workspaceId, libraryId } = this;
      const { keyword = null, ...filters } = this.filteredQuery ?? {
      };
      let search = null;
      if (keyword) {
        if (Array.isArray(keyword)) {
          search = keyword.join(' ').trim();
        } else {
          search = keyword;
        }
      }
      return {
        query: search ? search : undefined,
        workspaceId,
        ...(libraryId && {
          libraryId,
        }),
        filters,
      };
    },
    queryCriteria() {
      return this.$route.query.criteria;
    },
    activeGroupObj() {
      const { activeQueryCriteria: query } = this;
      if (!query) return null;
      return this.renderMapping.find(({ criteria }) => criteria === query);
    },
    activeGroupTitle() {
      const { title } = this.activeGroupObj ?? {
      };
      return title;
    },
    activeTitleIcon() {
      const { titleIcon } = this.activeGroupObj ?? {
      };
      return titleIcon;
    },
    isProductsCriteria() {
      return this.activeQueryCriteria === PRODUCTS_CRITERIA;
    },
    isWsPagesCriteria() {
      return this.activeQueryCriteria === WORKSPACE_PAGES_CRITERIA;
    },
    lastProductId() {
      return this.appropriateItems[this.appropriateItemsLength - 1]?.id;
    },
    appropriateItemsLength() {
      const { appropriateItems: arr } = this;
      if (!arr) return 0;
      return arr.length;
    },
    // This renders specifically product/collection/pages/etc when you expand.
    appropriateItemsToRendering() {
      const { isProductsCriteria, appropriateItems: list, activeQueryCriteria: criteria } = this;
      if (isProductsCriteria) return list;
      return this.setMappedList({
        criteria,
        list,
      });
    },
    showListingSkeleton() {
      return this.isLoadingSearchData && !this.itemsToRendering.length;
    },
    isLoadingAppropriateItems() {
      return this.isLoadingSearchData;
    },
  },
  watch: {
    // fetch new search results when filter is changed
    isWorkspaceOnlySearch() {
      this.parseSearchQuery();
    },
  },
  destroyed() {
    this.unsubscribe();
  },
  mounted() {
    this.setInitTempAsyncObj();
    this.setSectionsOrder();
    this.parseSearchQuery();
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapMutations('Collections', ['setIsLoadingSearchData', 'setItemsFromCollectionSearch', 'setSearchProductsMode']),
    ...mapActions(['handleError']),
    ...mapActions('Collections', ['setReadCollectionView']),
    /**
     * @param {Object} filters - The filters object to be processed.
     * @returns {Object} An object containing the remaining properties after excluding unused filters
     *                   for collections (currently justMANUFACTURER).
     *                   Returns original filters object if manufacturerFilter is falsy.
     */
    setFiltersForCollections(filters) {
      if (!this.manufacturerFilter) return filters;
      // Exclude manufacturer property, returning the rest.
      const { [MANUFACTURER]: manufacturerIgnored, ...rest } = filters ?? {
      };
      return rest;
    },
    // Set only relevant filters, workspace pages do not use a lot of filters like model/manufacturer etc.
    setFiltersForWorkspacePages(filters) {
      const wpFilters = {
      };
      for (const key of WORKSPACE_PAGE_FILTERS) {
        if (filters.hasOwnProperty(key)) {
          wpFilters[key] = filters[key];
        }
      }
      return wpFilters;
    },
    setSectionsOrder() {
      const { orderOfSections: arr } = this;
      this.renderMapping = arr.reduce((result, option) => {
        const obj = this.renderMapping.find(({ criteria }) => criteria === option);
        if (!obj) return result;
        return [...result, obj];
      }, []);
    },
    setInitTempAsyncObj() {
      const { orderOfSections: arr } = this;
      this.tempAsyncItems = arr.reduce((result, option) => {
        return {
          ...result,
          [option]: null,
        };
      }, {
      });
    },
    makeUniqArray(data) {
      return this.lodash.uniqBy(data, 'id');
    },
    clearItems() {
      this.clearSearchingGroupsResult();
      this.scrollID = null;
      this.lastIntersectedId = null;
    },
    clearSearchingGroupsResult() {
      this.renderMapping = this.renderMapping.map(item => ({
        ...item,
        dataArr: [],
      }));
    },
    setSpinner(val) {
      if (this.useLazyLoading) {
        this.setIsLoadingSearchData(val);
      } else {
        this.spinner(val);
      }
    },
    collectionRoute(item) {
      return {
        name: this.$route.meta.type == 'community' ? `community-collection` : `collection-library`,
        params: {
          id: item.collection,
        },
      };
    },
    workspacePageRoute({ id: pageId } = {
    }) {
      return {
        name: ROUTE_WORKSPACE_PAGE,
        params: {
          id: 'community',
          pageId,
        },
      };
    },
    parseSearchQuery() {
      const query = this.$route.query;
      const reducedItems = Object.keys(query).reduce((acc, key) => {
        const existedItem = query[key];
        if (this.filtersList.includes(key)) {
          if (key === VERIFIED) {
            acc[key] = existedItem === 'true';
          } else {
            acc[key] = existedItem;
          }
        }
        return acc;
      }, {
      });
      this.filteredQuery = reducedItems;
      this.searchIfExistsFilters(this.filteredQuery);
    },
    async setReadCollectionViewHandle(item) {
      const {
        collection: tableId = '',
        collectionName = '',
        id: rowId = '',
        editable = false,
        productInfo = {
          mode: '',
        },
      } = item || {
      };
      await this.setReadCollectionView({
        tableType: 'collection',
        tableId,
        rowId,
        rowVersionId: '',
        compareVersion: '',
        collectionName,
        editable,
        ...productInfo,
        collectionLink: this.collectionRoute(item),
      });
    },
    async handleItemClick(item) {
      const { criteria } = item ?? {
      };
      if (criteria === PRODUCTS_CRITERIA) {
        await this.setReadCollectionViewHandle(item);
      } else if (criteria === COLLECTIONS_CRITERIA) {
        this.$router.push(this.collectionRoute(item));
      } else if (criteria === WORKSPACE_PAGES_CRITERIA) {
        this.$router.push(this.workspacePageRoute(item));
      }
    },
    setAttachments({
      attachments,
      criteria,
    }) {
      if (!attachments?.length) {
        return [];
      }
      return criteria === PRODUCTS_CRITERIA ? [...attachments.slice(0, 1)] : attachments;
    },
    setMappedList({ list, criteria } = {
    }) {
      return list.map(({
        attachment: attachments,
        model,
        manufacturer,
        id,
        collectionName,
        collection,
        published,
        createdProUser,
        logo,
        docType,
        editable = false,
        name,
        productInfo,
        isVerified,
        childVerified = '',
        verifiedPageId = '',
        verifiedPageName = '',
      }) => ({
        title: criteria === PRODUCTS_CRITERIA ? `${manufacturer}, ${model}` : collectionName || name,
        attachment: this.setAttachments({
          attachments,
          criteria,
        }),
        id,
        productInfo,
        editable,
        collection,
        published,
        createdProUser,
        collectionName,
        isVerified,
        childVerified,
        verifiedPageId,
        verifiedPageName,
        ...((docType === 'collection' || criteria === WORKSPACE_PAGES_CRITERIA) && {
          logo,
        }),
      }));
    },
    onFilterSearch: debounce(async function (items) {
      const reducedItems = items.reduce((acc, { dataType, value, alias },) => {
        const arrFilter = dataType !== 'boolean';

        if (!acc[alias]) {
          acc[alias] = arrFilter ? [value] : value;
        } else {
          if (arrFilter) {
            acc[alias].push(value);
          } else {
            acc[alias] = value;
          }
        }
        return acc;
      }, {
      });
      if (this.customSearchKeyword && Object.keys(reducedItems).length) {
        if (!reducedItems['keyword']) {
          reducedItems['keyword'] = [this.customSearchKeyword];
        } else {
          reducedItems['keyword'].push(this.customSearchKeyword);
        }
      }
      let query = {
      };
      if (!isEmpty(reducedItems)) {
        query = {
          ...reducedItems,
          ...(this.activeQueryCriteria && {
            criteria: this.activeQueryCriteria,
          }),
        };
      }
      this.$router.replace({
        query,
      }).catch(() => {
      }); // @todo remove navigation duplicate error
      this.updateSearchFromProps = false;
      this.filteredQuery = reducedItems;
      this.searchIfExistsFilters(this.filteredQuery);
    }, DEBOUNCE_TIME_FOR_SEARCHING),
    searchIfExistsFilters(filters) {
      if (!isEmpty(filters)) {
        this.searchNewSet();
        this.setSearchProductsMode(true);
      } else {
        this.setSearchProductsMode(false);
        this.setSpinner(false);
        this.clearItems();
        this.clearQuery();
        // described in AsyncSearching mixin
        this.unsubscribe();
      }
    },
    clearQuery() {
      this.activeQueryCriteria = null;
      this.$router.replace({
        query: {
        },
      }).catch(() => {
      });
    },
    async searchNewSet() {
      this.clearItems();
      this.activeQueryCriteria = this.queryCriteria;
      await this.$nextTick();
      if (this.activeQueryCriteria) {
        this.asyncSearchAppropriateItems();
      } else {
        this.asyncSearchAll();
      }
    },
    compareSearchMatches({ searchQuery } = {
    }) {
      // for checking the correct sequence of answers to queries
      return (!isEmpty(searchQuery) || !isEmpty(this.variablesForSearching)) && !isEqual(searchQuery, this.variablesForSearching);
    },
    async setSubscriptions() {
      await this.asyncSearchingSubscription({
        callback: this.setAsyncData,
      });
      const { wsPagesRenderingObject: wsPages, isAllowWsPagesSearching } = this;
      if (!wsPages || !isAllowWsPagesSearching) {
        delete this.tempAsyncItems[WORKSPACE_PAGES_CRITERIA];
        this.removeSubscriptions([
          ASYNC_SEARCH_WORKSPACE_PAGES,
        ]);
        return;
      }
      const { subscriptionFunc, subscriptionTitle } = wsPages;
      await this.asyncSearchingSubscription({
        callback: this.setAsyncData,
        subscriptionTitle,
        subscriptionFunc,
      });
    },
    async asyncSearchAppropriateItems({ limit = 18 } = {
    }) {
      const { wsPagesRenderingObject: wsPages, isAllowWsPagesSearching, isWsPagesCriteria } = this;
      if (isWsPagesCriteria && wsPages && !isAllowWsPagesSearching) {
        this.setSpinner(false);
        this.removeSubscriptions([
          ASYNC_SEARCH_WORKSPACE_PAGES,
        ]);
        return;
      }
      this.setSpinner(true);
      const { activeGroupObj: listObj } = this;
      if (!listObj) return;
      try {
        this.currentRequestId = uuid();
        const variables = {
          ...this.variablesForSearching,
          limit,
          requestId: this.currentRequestId,
          manufacturerQuery: this.manufacturerFilterQuery,
        };
        const { specificAsyncQueryForRequest: request } = listObj ?? {
        };
        if (!request) return;
        const { request: queryForRequest, nameRequest, initVariables } = this[request] || {
        };
        const {
          filters: initFilters = {
          }, ...rest
        } = initVariables || {
        };
        const filters = {
          ...variables.filters,
          ...initFilters,
        };
        const variablesToRequest = {
          ...variables,
          scrollID: this.scrollID,
          filters,
          collectionsFilters: this.setFiltersForCollections(filters),
          wpFilters: this.setFiltersForWorkspacePages(filters),
          ...rest,
        };
        this.currentParseMethod = this.setSearchAppropriateItems;
        await this.setSubscriptions();
        xRay.startTimeTracker(nameRequest);
        await queryForRequest(variablesToRequest);
      } catch (err) {
        this.setSpinner(false);
        this.handleError(err);
      }
    },
    async asyncSearchAll({ limit = 4 } = {
    }) {
      this.setSpinner(true);
      try {
        this.currentRequestId = uuid();
        const variables = {
          ...this.variablesForSearching,
          limit,
          requestId: this.currentRequestId,
          manufacturerQuery: this.manufacturerFilterQuery,
          wpSort: {
            isVerified: 'desc',
          },
        };
        this.currentParseMethod = this.setAsyncSearchAllData;
        await this.setSubscriptions();
        this.startTimeTrackerXray();
        const { request, initVariables } = this.asyncCollectionsAndProductsApiMethod || {
        };
        const {
          filters: initFilters = {
          }, ...rest
        } = initVariables || {
        };
        const filters = {
          ...variables.filters,
          ...initFilters,
        };
        const variablesToRequest = {
          ...variables,
          filters,
          ...rest,
          collectionsFilters: this.setFiltersForCollections(filters),
          wpFilters: this.setFiltersForWorkspacePages(filters),
        };
        if (!request) return;
        await request(variablesToRequest);
      } catch (err) {
        this.setSpinner(false);
        this.handleError(err);
      }
    },
    setAsyncData(data) {
      this.currentParseMethod(data);
    },
    setAsyncSearchAllData({ data } = {
    }) {
      const { queryName = '', ...rest } = data ?? {
      };
      xRay.stopTimeTracker(queryName);
      const mappedArrName = SEARCH_V2_QUERIES[queryName];
      this.tempAsyncItems = {
        ...this.tempAsyncItems,
        [mappedArrName]: rest,
      };
      const isObjectHasNullProp = Object.values(this.tempAsyncItems).some(item => !item);
      if (!isObjectHasNullProp) {
        xRay.sendSegmentsToAws();
        this.setSearchAllData({
          data: {
            ...this.tempAsyncItems,
          },
        });
        this.setInitTempAsyncObj();
      }
    },
    setSearchAllData({ data } = {
    }) {
      this.renderMapping = this.renderMapping.map(item => {
        const { criteria } = item ?? {
        };
        const { [criteria]: obj } = data ?? {
        };
        const { data: arr } = obj ?? {
        };
        return {
          ...item,
          dataArr: arr || [],
        };
      });
      this.setSpinner(false);
    },
    setSearchAppropriateItems({ data } = {
    }) {
      const { data: dataProducts = [], scrollID = null, queryName = '' } = data ?? {
      };
      xRay.stopTimeTracker(queryName);
      xRay.sendSegmentsToAws();
      this.appropriateItems = this.scrollID ?
        this.makeUniqArray([...this.appropriateItems, ...dataProducts]) : dataProducts;
      this.scrollID = scrollID;
      if (!this.isExistQueryRequest) {
        this.appropriateItems = [];
        this.scrollID = null;
      } else if (!dataProducts.length && this.scrollID) {
        this.scrollID = null;
      }
      this.setSpinner(false);
    },
    parseSearchAppropriateItems({ data, variables } = {
    }) {
      delete variables.limit;
      if (this.compareSearchMatches({
        searchQuery: variables,
      })) {
        return;
      }
      this.setSearchAppropriateItems({
        data,
      });
    },
    async onIntersect({ entries, isIntersecting }) {
      if (isIntersecting) {
        const productId = entries[0].target.id;
        if (this.scrollID && productId === this.lastProductId && this.lastIntersectedId !== productId) {
          this.lastIntersectedId = productId;
          await this.asyncSearchAppropriateItems();
        }
      }
    },
    startTimeTrackerXray() {
      const { arrNameRequest } = this;
      arrNameRequest.forEach(e => xRay.startTimeTracker(e));
    },
  },
};
</script>

<style scoped>
.h-100 {
  height: 100%;
}
</style>
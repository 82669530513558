import { debounce } from 'lodash';
export default {
  data() {
    return {
      showFocusWarning: false,
      isCreatedEvent: false,
      autofocusElements: [],
      additionalFocusElements: ['a.v-tab.v-tab--active'],
      acceptedElements: ['.v-dialog__content--active', '.dialog-content', '.v-card'],
    };
  },
  created() {
    this.debouncedFocusOut = debounce(this.onFocusOut, 200);
  },
  methods: {
    /**
     * Triggers when we open modal
     */
    createFocusEvent() {
      try {
        document.addEventListener('focusout', this.debouncedFocusOut);
        setTimeout(() => {
          this.isCreatedEvent = true;
          this.autofocusElements.push('input');
        }, 500);
      } catch (e) {
        console.log(e);
      }
    },
    /**
     * Triggers when we close modal
     */
    removeFocusEvent() {
      this.isCreatedEvent = false;
      document.removeEventListener('focusout', this.debouncedFocusOut);
      this.showFocusWarning = false;
    },
    /**
     * Triggers when we click on focus element
     */
    onFocusIn() {
      this.showFocusWarning = false;
    },
    removeElements(elementsToRemove = [], type = 'accepted') {
      const elements = `${type}elements`;
      this[elements] = this[elements].filter(el => !elementsToRemove.includes(el));
    },
    /**
     * @param { FocusEvent } e
     * Focus out event is triggered when any element inside document
     * bubble up event focusOut
     */
    async onFocusOut(e) {
      try {
        if (!this.isCreatedEvent) {
          return;
        }
        /**
         * When focus works wrong
         * we will focus on input which we use with autofocus
         */
        if (this.additionalFocusElements.some(el => e.target.matches(el))) {
          await this.$nextTick();
          const input = this.$refs.dialogInput?.$el?.querySelector('input');
          if (!input) return;
          setTimeout(() => {
            input.focus();
          }, 0);
          return;
        }
        /**
         * In case when our autofocus element loses
         * focus we will only show warning if the click will be outside tab
         */
        if (!e.relatedTarget && this.autofocusElements.some(el => e.target.matches(el))) {
          this.showFocusWarning = true;
          return;
        }
        /**
         * In case if we will detect focus out on other elements
         */
        if (this.acceptedElements.some(el => e.target.matches(el))) {
          this.showFocusWarning = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};

import FileResource from '@/utils/files/FileResource';
import AwsResource from '@/utils/files/AwsResource';
import BaseResource from '@/utils/files/BaseResource';
export default class FileProcessor {
  static isFileResource(file) {
    return file instanceof File;
  }
  static isAwsResource(file) {
    return !!(typeof file == 'object' && file?.filename && file?.id && file?.key);
  }
  static isBlobResource(file) {
    return file instanceof Blob;
  }
  static createResource({ file }) {
    if (FileProcessor.isFileResource()) {
      return new FileResource({
        file,
      });
    }
    if (FileProcessor.isAwsResource()) {
      return new AwsResource({
        file,
      });
    }
    return new BaseResource({
      file,
    });
  }
}

<template>
  <div
    v-if="displaySwitcher"
    class="d-flex align-center mb-0">
    <v-tooltip
      v-if="showIcon"
      bottom>
      <template #activator="{ on, attrs }">
        <div
          :class="{ 'green': inputValue }"
          class="pointer nav-svg-icon icon-gallery"
          v-bind="attrs"
          v-on="on"
          @click="toggleView(!inputValue)" />
      </template>
      <span>{{ inputValue ? 'Listing' : 'Gallery' }}</span>
    </v-tooltip>

    <v-switch
      v-else
      :data-test="!inputValue ? 'toggle_listing_view' : 'toggle_gallery_view'"
      :input-value="inputValue"
      color="black"
      class="ma-0 pa-0"
      hide-details
      inset
      @change="toggleView">
      <template #label>
        <span class="fs-12 font-weight-bold text-uppercase black--text">
          {{ inputValue ? 'Gallery' : 'Listing' }}
        </span>
      </template>
    </v-switch>
  </div>
</template>
<script>
import LoadingSpinnerTimeout from '@/mixins/LoadingSpinnerTimeout';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';
import ToggleView from '@/mixins/ToggleViewMode';
import {
  mapMutations, mapGetters, mapState,
} from 'vuex';
import {
  ROUTE_WORKSPACE_PAGE, ROUTE_WORKSPACE_PAGE_EDIT,
} from '@/constants';
export default {
  name: 'AppSwitcherToListing',
  mixins: [LoadingSpinnerTimeout, ToggleViewCondition, ToggleView],
  props: {
    showIcon: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...mapGetters('FeatureFlags', ['useSkeleton']),
    ...mapState(['isChangingView']),
    listingSwitcherInHeader() {
      return [
        'shared-workspace',
        'shared-collection',
        'libraries',
        'community-collections',
        'collections',
        'collection-library',
        'my-page',
        'verified-community',
        'collection-product-link',
        ROUTE_WORKSPACE_PAGE_EDIT,
        ROUTE_WORKSPACE_PAGE,
        'community-collection',
        'schedule-library',
        'project-library',
      ].includes(this.$route.name);
    },
    inputValue() {
      return (this.value === null)
        ? this.toggleViewCondition('librariesRelatedPages')
        : this.value;
    },
    displaySwitcher() {
      return this.listingSwitcherInHeader && !this.$route.query.keyword;
    },
  },
  methods: {
    ...mapMutations(['setIsLoadingRowData', 'setIsChangingView']),
    async toggleView(val) {
      clearTimeout(this.syncTime);
      if (this.useSkeleton) {
        this.setIsLoadingRowData(true);
        this.setIsChangingView(true);
        // if no value is passed to the component then run the deault functionality
        if (this.value === null) {
          this.toggleViewMode({
            value: val,
            criteria: 'librariesRelatedPages',
          });
        } else {
          this.$emit('toggleViewMode', val);
        }
      } else {
        this.syncTime = setTimeout(() => {
          // if no value is passed to the component then run the deault functionality
          if (this.value === null) {
            this.toggleViewMode({
              value: val,
              criteria: 'librariesRelatedPages',
            });
          } else {
            this.$emit('toggleViewMode', val);
          }
        }, 10);
      }
    },
  },
};
</script>

<template>
  <div
    :style="cadBimStyles"
    class="cad-bim"
    @click="download">
    <div>
      <img
        :style="cadBimImgStyles"
        :src="cadBimImg"
        alt="">
    </div>
    <p
      v-if="showNameFile"
      class="text-center text-truncate mb-0">
      {{ name }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'CadBimFile',
  props: {
    showNameFile: {
      type: Boolean,
      default: true,
    },
    file: {
      type: Object,
      required: true,
    },
    cadBimImgStyles: {
      type: Object,
      default: () => ({
      }),
    },
    cadBimStyles: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      cadBimImg: require('@/assets/icons/projects/cadBim.png'),
    };
  },
  computed: {
    name() {
      const { filename } = this.file;
      return filename;
    },
  },
  methods: {
    download() {
      this.$emit('download', this.file);
    },
  },
};
</script>
<style lang="scss" scoped>
.cad-bim {
  margin: auto 0;
  transition: .4s;
  img {
    width: 100%;
    display: block;
  }
  p {
    margin-top: 5px;
    font-size: 16px;
    line-height: 120%;
  }
  &:hover {
    transform: scale(0.9);
  }
}
</style>

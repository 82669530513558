<template>
  <div>
    <v-combobox
      ref="menu"
      v-model="select"
      :class="{'disabled-input':!editMode}"
      :items="items"
      dense
      hide-no-data
      hide-selected
      deletable-chips
      :disabled="readMode"
      append-icon=""
      hide-details
      allow-overflow
      :menu-props="{
        auto:true,
        attach:true,
        width:'180px',
        maxWidth:'180px',
      }"
      chips
      @change="save">
      <template #selection="{item}">
        <v-chip
          class="ma-2"
          :close="!readMode"
          @click:close="removeTag">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div
                class="text-truncate"
                v-on="on">
                {{ item }}
              </div>
            </template>
            <span>{{ item }}</span>
          </v-tooltip>
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>
<script>
export default {
  name: 'DocumentCellTags',
  props: {
    readMode: {
      type: Boolean,
      default: false,
    },
    selectedTag: {
      type: String,
      default: '',
    },
    documentId: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      select: null,
      items: [],
    };
  },
  watch: {
    selectedTag: {
      immediate: true,
      handler(val) {
        this.select = val;
      },
    },
    tags: {
      immediate: true,
      handler(val) {
        this.items = val;
      },
    },
    editMode(val) {
      setTimeout(() => {
        this.$refs.menu.isMenuActive = val;
        this.$refs.menu.isFocused = val;
      }, 0);
    },
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.menu.isMenuActive;
      },
      (val) => {
        if (!val) this.$emit('controlEditMode', false);
      }
    );
  },
  methods: {
    removeTag() {
      this.select = null;
      this.$emit('setTags', null, this.documentId);
      this.$emit('controlEditMode', false);
    },
    save(val) {
      this.$emit('setTags', val, this.documentId);
      this.$emit('controlEditMode', false);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
  content: none !important;
}
::v-deep .disabled-input .v-input__control .v-select__slot input {
  display: none !important;
}
::v-deep .v-autocomplete.v-input > .v-input__control > .v-input__slot {
  cursor: pointer !important;
}
::v-deep .v-chip {
  &__content {
    font-size: 16px;
  }
}
</style>

<template>
  <v-badge
    :content="unReadCommentsLength"
    :value="unReadCommentsLength > 0"
    class="pointer"
    color="red"
    bordered
    dot
    overlap>
    <div
      class="comments-container"
      @click="$emit('click')">
      <img
        v-if="$route.name !== 'collection-library' && isCellBadge"
        :class="{'comments-icon__clicked-comment': clickedComment}"
        src="@/assets/icons/comments-icon.svg"
        class="comments-icon">
      <span
        class="comments-container__text">
        {{ allCommentsLength || '-' }}
      </span>
    </div>
  </v-badge>
</template>
<script>
import {
  TYPE_CELL_BADGE,
  TYPE_ROW_BADGE,
} from '@/constants';
export default {
  name: 'ProjectDetailsCommentBadge',
  props: {
    item: {
      type: Object,
      default: null,
    },
    badgeType: {
      type: String,
      default: TYPE_CELL_BADGE,
      validator(value) {
        return [TYPE_CELL_BADGE, TYPE_ROW_BADGE].includes(value);
      },
    },
  },
  computed: {
    clickedComment() {
      const { item, allCommentsLength, isCellBadge } = this;
      return this.$route.query.rowId === item.id || Number(allCommentsLength) && isCellBadge;
    },
    slant() {
      if (this.isRowBadge) {
        return '';
      }
      const { item } = this;
      return this.$store.getters['Comments/slant'](item.id);
    },
    isCellBadge() {
      return this.badgeType == TYPE_CELL_BADGE;
    },
    isRowBadge() {
      return this.badgeType == TYPE_ROW_BADGE;
    },
    unReadCommentsLength() {
      const { item, slant, isRowBadge } = this;
      if (isRowBadge) {
        return item.commentsUnread;
      }
      if (item?.resolvedComments) {
        return item.unresolved;
      }
      return slant?.commentsUnread;
    },
    allCommentsLength() {
      const { item, isCellBadge } = this;
      return isCellBadge
        ? item.commentsAmount
        : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.comments-container {
  position: relative;

  &__text {
    font-size: 12px;
    position: absolute;
    color: var(--v-black-base);
    top: 6px;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
  }
}
</style>

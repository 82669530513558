export default {
  props: {
    col: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    expandedRow: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    isArray: {
      type: Boolean,
      default: false,
    },
    value: {
      type: undefined,
      default: null,
    },
  },
  methods: {
    async toggleEditMode() {
      this.$emit('toggleEditMode');
    },
    async setEditMode(val) {
      this.$emit('setEditMode', val);
    },
  },
};

<template>
  <div :class="[wrapperClass]">
    <v-autocomplete
      v-model="selectedItem"
      :items="items"
      :multiple="false"
      :placeholder="placeholder"
      item-text="name"
      item-value="id"
      class="border-outline"
      attach
      flat
      hide-details
      return-object
      solo
      @change="$emit('change', {
        value: selectedItem || [],
      })">
      <template v-slot:item="{ item }">
        <v-tooltip
          bottom
          :disabled="!item.disabled">
          <template #activator="{ on: onTooltip }">
            <v-layout
              wrap
              v-on="onTooltip">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-layout>
          </template>
          <span>
            {{ item.tooltipText }}
          </span>
        </v-tooltip>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import EditModeChildComponent from '@/mixins/EditModeChildComponent';
import {
  isLimitOfProducts,
  setTitleToLimitOfProducts,
} from '@/utils';
export default {
  name: 'ProductCellSelectV2',
  mixins: [EditModeChildComponent],
  props: {
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    selectItems: {
      type: Array,
      default: () => [],
    },
    wrapperClass: {
      type: String,
      default: 'base-select__wrapper',
    },
    placeholder: {
      type: String,
      default: 'Choose collection',
    },
  },
  data() {
    return {
      selectedItem: [],
    };
  },
  computed: {
    items() {
      return this.selectItems.map(collection => ({
        ...collection,
        ...(isLimitOfProducts(collection) && {
          disabled: true,
          tooltipText: setTitleToLimitOfProducts(collection),
        }),
        ...(collection?.readOnly && {
          disabled: true,
          tooltipText: 'Collection in read only mode',
        }),
      }));
    },
  },
  watch: {
    value: {
      handler(val) {
        this.checkSelectedItem(val);
      },
      immediate: true,
    },
  },
  methods: {
    checkSelectedItem(val) {
      if (!val) {
        return;
      }
      const disabled = this.items.find(({ id }) => id === val)?.disabled;
      if (disabled) {
        this.selectedItem = [];
      } else {
        this.selectedItem = val;
      }
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin: auto;
}
.base-select__wrapper {
  ::v-deep .v-list-item--disabled {
    opacity: 0.5;
    pointer-events: auto;
  }
}
</style>

const fixedColors = [
  {
    backgroundColor: 'rgba(120, 46, 138, 1)',
    textColor: 'white',
    outlined: false,
  },
  {
    backgroundColor: 'rgba(207, 0, 114, 1)',
    textColor: 'white',
    outlined: false,
  },
  {
    backgroundColor: 'rgba(255, 0, 107, 1)',
    textColor: 'white',
    outlined: false,
  },
  {
    backgroundColor: 'rgba(255, 125, 164, 1)',
    textColor: 'white',
    outlined: false,
  },
  {
    backgroundColor: 'rgba(122, 184, 0, 1)',
    textColor: 'white',
    outlined: false,
  },
  {
    backgroundColor: 'rgba(22, 126, 5, 1)',
    textColor: 'white',
    outlined: false,
  },
  {
    backgroundColor: 'rgba(186, 189, 17, 1)',
    textColor: 'white',
    outlined: false,
  },
  {
    backgroundColor: 'rgba(113, 214, 224, 1)',
    textColor: 'white',
    outlined: false,
  },
  {
    backgroundColor: 'rgba(64, 164, 186, 1)',
    textColor: 'white',
    outlined: false,
  },
  {
    backgroundColor: 'rgba(235, 80, 14, 1)',
    textColor: 'white',
    outlined: false,
  },
  {
    backgroundColor: 'rgba(233, 153, 74, 1)',
    textColor: 'white',
    outlined: false,
  },
  {
    backgroundColor: 'rgba(255, 191, 67, 1)',
    textColor: 'white',
    outlined: false,
  },
];
const customColor = {
  backgroundColor: '#979797',
  textColor: 'white',
  outlined: false,
};
const getNextFixedColor = index => fixedColors[(index + 1) % fixedColors.length];
export {
  getNextFixedColor,
  customColor,
};


<!--
  This component renders a dropdown
  accepts items as the list to be rendered
  can also accept default item for initially selected item
  on change emits `resort`
-->
<template>
  <v-select
    v-model="selectedItem"
    :disabled="disabled"
    :items="items"
    :menu-props="{
      nudgeBottom: 42,
    }"
    color="black"
    class="sourcery__select"
    dense
    full-width
    hide-details
    outlined
    rounded
    return-object
    @change="$emit('resort', selectedItem)">
    <template #append>
      <span class="sourcery__icon-wrapper black size-22">
        <img src="@/assets/icons/chevron-down-icon.svg">
      </span>
    </template>

    <template #selection="{ item, attrs }">
      <span
        v-bind="attrs"
        class="fs-14 text-uppercase">
        {{ item.name }}
      </span>
    </template>

    <template #item="{ item, on }">
      <v-list-item
        :class="{'v-item--active v-list-item--active': item.name === selectedItem.name }"
        v-on="on">
        <v-list-item-title class="text-capitalize">
          {{ item.name }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'AppSelect',
  props: {
    defaultItem: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    switchSelected: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    selectedItem: '',
  }),
  watch: {
    switchSelected(nVal) {
      if (nVal) {
        this.selectedItem = nVal;
      }
    },
  },
  created() {
    this.selectedItem = this.defaultItem || this.items[0];
  },
};
</script>
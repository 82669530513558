<template>
  <div
    class="wrapper"
    style="height: 100%"
    @click="openPreview">
    <img-pdf-file
      v-if="isImg || isPdf"
      disabled-custom-size-pdf
      :thumbnail-size="sizeImg"
      :file="item"
      @setFilesPreview="openPreview" />
    <v-icon
      v-else
      size="60">
      mdi-file-document
    </v-icon>
    <AppFileImagePreview
      :items="filesForPreview"
      @clearFilePreview="resetFilePreview" />
  </div>
</template>
<script>
import AppFileImagePreview from '@/components/App/AppFileImagePreview';
import { mapActions } from 'vuex';
import ImgPdfFile from '@/components/Product/ProductCell/ProductCellAttachmentV2/imgPdfFile';
export default {
  name: 'DocumentReviewAttachment',
  components: {
    ImgPdfFile,
    AppFileImagePreview,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    sizeImg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filesForPreview: [],
    };
  },
  computed: {
    type() {
      return this.item?.type;
    },
    isImg() {
      return this.type.includes('image');
    },
    isPdf() {
      return this.type === 'application/pdf';
    },
  },
  methods: {
    ...mapActions(['downloadFileByBlob']),
    openPreview() {
      if (!this.isImg && !this.isPdf) {
        const { url, filename, type } = this.item;
        this.downloadFileByBlob({
          url,
          filename,
          type,
        });
      } else this.filesForPreview = [this.item];
    },
    resetFilePreview() {
      this.filesForPreview = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  ::v-deep .wrapper-pdf {
    max-width: 100px;
    height: 100%;
    .file-image__pdf {
      height: 100%;
    }
    canvas {
      height: 100% !important;
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>

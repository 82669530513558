<template>
  <span
    v-if=" count && isShowCounter"
    class="additional-images-count"> + {{
      count
    }}</span>
</template>
<script>
export default {
  name: 'CounterAttachment',
  props: {
    isShowCounter: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang="scss" scoped>
.additional-images-count {
  font-weight: bold;
  font-size: 13px;
  white-space: pre;
  &--absolute {
    position: absolute;
    right: -25px;
    bottom: 50%;
    transform: translateY(50%);
  }
}
</style>

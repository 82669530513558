import EditModeChild from '@/mixins/EditModeChildComponent';
import { CurrencyDirective } from 'vue-currency-input';
import { mapMutations } from 'vuex';
export default {
  directives: {
    currency: CurrencyDirective,
  },
  mixins: [EditModeChild],
  data () {
    return {
      newValue: '',
      currencyOptions: {
        distractionFree: false,
        currency: null,
        locale: 'en',
      },
    };
  },
  watch: {
    editMode(value) {
      if (value) {
        this.calculateNewValue();
      }
    },
    value: {
      handler() {
        this.calculateNewValue();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    checkIsInteger(val) {
      let splited = val.toString().split('.');
      return splited[1] === '00' || splited[1] === '0' ? splited[0] : val;
    },
    update() {
      if (this.newValue) {
        let replaced = this.newValue;
        replaced = replaced.replace(/,/g, '');
        this.$emit('change', {
          value: `${Number.parseFloat(replaced)} `,
        });
      } else {
        this.$emit('change', {
          value: '0',
        });
      }
      this.toggleEditMode();
    },
  },
};
<template>
  <v-text-field
    autofocus
    autocomplete="off"
    hide-details
    style="width: 0; height: 0"
    @focus="$emit('focus')" />
</template>
<script>
export default {
  name: 'AppFocusWarningInput',
};
</script>
<style scoped>
</style>


import requestBuilder from '@/services/graphql/requestBuilder';
import { ASYNC_SEARCH_RESULT } from '../fragments';
import {
  SEARCH_COLLECTIONS_V3, SEARCH_COMMUNITY_COLLECTIONS_V3,
} from '@/constants/searching/searchingAsyncV2';
export default {
  asyncCommunityCollectionsV3(initVariables) {
    const { manufacturerQuery: query, collectionsFilters: filters } = initVariables ?? {
    };
    const variables = {
      ...initVariables,
      manufacturerQuery: undefined,
      collectionsFilters: undefined,
      ...(query && {
        query,
      }),
      filters,
    };
    return new requestBuilder({
      requestName: SEARCH_COMMUNITY_COLLECTIONS_V3,
      requestVariables: {
        query: 'String',
        limit: 'Int',
        scrollID: 'String',
        workspaceId: 'String',
        filters: 'searchFilters',
        sort: 'SearchSortingOptions',
        requestId: 'String',
      },
      response: ASYNC_SEARCH_RESULT,
      variables,
    }).processRequest();
  },
  asyncCollectionsV3(initVariables) {
    const { manufacturerQuery: query, collectionsFilters: filters } = initVariables ?? {
    };
    const variables = {
      ...initVariables,
      manufacturerQuery: undefined,
      collectionsFilters: undefined,
      ...(query && {
        query,
      }),
      filters,
    };
    return new requestBuilder({
      requestName: SEARCH_COLLECTIONS_V3,
      requestVariables: {
        query: 'String',
        limit: 'Int',
        scrollID: 'String',
        workspaceId: 'String',
        libraryId: 'String',
        filters: 'searchFilters',
        requestId: 'String',
        myCollections: 'Boolean',
        followedOnly: 'Boolean',
      },
      response: ASYNC_SEARCH_RESULT,
      variables,
    }).processRequest();
  },
};

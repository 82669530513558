export default {
  methods: {
    setPictures({ logo: key = '', pictures = [] }) {
      if (!pictures) {
        return [];
      }
      return [
        (pictures && pictures[0]),
        ...(key ? [{
          key,
          isLogo: true,
        }] : []),
      ];
    },
  },
};

<template>
  <app-table-member
    :color-icon="profile.color || ''"
    :member="profile" />
</template>
<script>
import AppTableMember from '@/components/App/AppTable/AppTableMember';
export default {
  name: 'DocumentCellIcon',
  components: {
    AppTableMember,
  },
  props: {
    profile: {
      type: Object,
      default: () => {
      },
    },
  },
};
</script>
<style scoped>
</style>

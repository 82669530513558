<template>
  <CommunityLayout
    :groups-arr-to-rendering="groupsArrToRendering"
    :props-data-to-sections="propsDataToSections"
    :props-data-for-searching="propsDataForSearching"
    :breadcrumb-items="breadcrumbItems"
    :data-store="dataStore"
    :is-listing-view="isListingView"
    :is-loading="isLoading"
    @manageActions="$emit('manageActions', $event)"
    @onIntersect="onIntersect"
    @sortCommunity="sortCommunityData" />
</template>
<script>
import {
  mapGetters,
  mapMutations,
  mapState,
  mapActions,
} from 'vuex';
import {
  last, uniqBy,
} from 'lodash';

import SortingApi from '@/services/graphql/sorting';
import {
  subscribeSearchWorkspacePagesResult,
  subscribeSearchResultToVerifiedArea,
} from '@/services/graphql/searching/asyncSearchSubscriptions';

import AsyncSearching from '@/mixins/AsyncSearching';
import SearchingProps from '@/mixins/SearchingProps';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';

import { SEARCH_V2_QUERIES } from '@/constants/searching/searchingAsyncV2';
import {
  COMMUNITY_PAGES_MAPPING,
  VERIFIED_WS_PAGES_MAPPING,
} from '@/constants/collectionsList';
import {
  COMMUNITY_WS_PAGES_SEARCH_TITLE,
  COMMUNITY_COLLECTIONS_SEARCH_TITLE,
} from '@/constants/subscriptions';
import {
  SORTING_MODE_LAST_ACCESSED,
  SORTING_MODE_CREATION_DATE,
  SORTING_MODE_COLLECTION_NAME_ACS,
} from '@/constants/sortable';

import CommunityLayout from './CommunityLayout';

const DEFAULT_LIMIT = 30;
export default {
  name: 'CommunityGroupsWithSearchResults',
  components: {
    CommunityLayout,
  },
  mixins: [
    SearchingProps,
    AsyncSearching,
    ToggleViewCondition,
  ],
  props: {
    propsDataForSearching: {
      type: Object,
      default: () => {
      },
    },
    breadcrumbItems: {
      type: Object,
      default: () => {
      },
    },
    groupsArrToRendering: {
      type: Array,
      default: () => [],
    },
    propsDataToSections: {
      type: Object,
      default: () => {
      },
    },
    /**
     * Query for async collections and ws pages searching
     * @param variables
     * @returns {object}
     */
    asyncCollectionsAndWsPagesApiMethod: {
      type: Object,
      default: () => {
      },
    },
    asyncWsPagesApiMethod: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      subscriptionTitles: [
        COMMUNITY_COLLECTIONS_SEARCH_TITLE,
        COMMUNITY_WS_PAGES_SEARCH_TITLE,
      ],
      dataLocalStore: {
      },
      scrollIDs: {
      },
      tempAsyncItems: {
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState('Collections', ['isFetching']),
    ...mapGetters({
      getItemFromCollectionSearch: 'Collections/getItemFromCollectionSearch',
      getFollowedCollectionsList: 'Collections/getFollowedCollectionsList',
    }),
    ...mapGetters('FeatureFlags', ['useLazyLoading', 'useSkeleton']),
    accessToken() {
      return this.$route?.query?.accessToken;
    },
    dataStore: {
      get() {
        return this.getDataStore();
      },
      set(val) {
        this.setDataStore(val);
      },
    },
    isListingView() {
      return this.toggleViewCondition('librariesRelatedPages');
    },
  },
  watch: {
    async isListingView() {
      await this.$nextTick();
      this.setUploadingSpinner(false);
    },
  },
  created() {
    this.setInitTempAsyncObj();
  },
  mounted() {
    this.setSubs();
    this.fetchCommunityData();
  },
  beforeDestroy() {
    this.unsubscribeHandler();
    this.groupsArrToRendering.map(el => {
      this.setIsCancelled({
        group: el.group,
        value: null,
      });
    });
  },
  methods: {
    ...mapMutations({
      setUploadingSpinner: 'setUploadingSpinner',
      spinner: 'spinner',
      setIsCancelled: 'Collections/setIsCancelled',
      setIsFetching: 'Collections/setIsFetching',
      setIsLoadingRowData: 'setIsLoadingRowData',
      setUserData: 'UserProfile/setUserData',
      setFollowedCollectionsList: 'Collections/setFollowedCollectionsList',
    }),
    ...mapActions({
      subscribeCollectionFollowAndUnfollow: 'Collections/subscribeCollectionFollowAndUnfollow',
      unsubscribeCollectionFollowAndUnfollow: 'Collections/subscribeCollectionFollowAndUnfollow',
    }),
    /**
     * Fetches data for all the headings displayed on the page
     */
    async fetchCommunityData() {
      this.isLoading = true;
      const requestArr = this.groupsArrToRendering.reduce((finalArr, obj) => {
        const { request = null, list } = obj;

        if (request) {
          let params = this.getAppropriateParams(obj);
          finalArr.push({
            request, params, list,
          });
        }
        return finalArr;
      }, []);

      // dispatch actions to fetch data
      await Promise.all(
        requestArr.map(({ request, params }) => this.$store.dispatch(request, params))
      );
      this.isLoading = false;
    },
    /**
     * return params for request to be sent to backend
     */
    getAppropriateParams({ group, allowGetAllItems } = {
    }) {
      return {
        libraryId: this.activeWorkspaceId,
        collectionGroup: group,
        allowGetAllItems: allowGetAllItems,
        isWsPage: false,
        showSpinner: !this.useLazyLoading,
        filteredListOfCollection: this.groupsArrToRendering,
        ...(this.accessToken && {
          accessToken: this.accessToken,
        }),
        resetCancellation: true,
        comingFromRoute: this.$route.name,
      };
    },
    getDataStore() {
      const { groupsArrToRendering: arr } = this;
      return arr.reduce((result, { list: listName, mappingName } = {
      }) => {
        if (!listName || !mappingName) {
          const { [mappingName]: localList } = this.dataLocalStore || {
          };
          return {
            ...result,
            ...{
              [mappingName]: localList,
            },
          };
        }
        const list = this.$store.getters[listName] || {
        };
        if (!Array.isArray(list)) return result;
        return {
          ...result,
          [mappingName]: list,
        };
      }, {
      });
    },
    getSortingFn(sortedParam) {
      switch (sortedParam.sortingMode) {
      case SORTING_MODE_LAST_ACCESSED:
        return this.sortLastModified;
      case SORTING_MODE_CREATION_DATE:
        return this.sortcreatedDate;
      case SORTING_MODE_COLLECTION_NAME_ACS:
      default:
        return sortedParam.ascending ? this.sortNameAsc : this.sortNameDesc;
      }
    },
    // sorting based on last accessed desc
    sortLastModified(a, b) {
      return parseInt(b.lastModified) - parseInt(a.lastModified);
    },
    // based on created date desc
    sortcreatedDate(a, b) {
      return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
    },
    sortNameAsc(a, b) {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    },
    sortNameDesc(a, b) {
      return b.name.toLowerCase() < a.name.toLowerCase() ? -1 : 1;
    },
    async sortCommunityData(sortedParam) {
      this.setSpinner(true);
      if (this.useSkeleton) this.setIsLoadingRowData(true);

      if (sortedParam) {
        const { data } = await SortingApi.setSortingMode({
          resourceType: sortedParam.resourceType,
          sortingMode: sortedParam.sortingMode,
          ascending: sortedParam.ascending,
        });
        this.setUserData(data.response);
      }

      // sort data store
      const { dataStore } = this;

      const sortFn = this.getSortingFn(sortedParam);

      for (let item of Object.keys(dataStore)) {
        let list = dataStore[item];
        list.sort((a, b) => sortFn(a, b));
      }
    },
    setInitTempAsyncObj() {
      this.tempAsyncItems = {
        collections: null,
        wsPages: null,
      };
    },
    setDataStore(val) {
      const { groupsArrToRendering: arr } = this;
      for (const item of arr) {
        const { mutationName, mappingName } = item || {
        };
        const { [mappingName]: list } = val || {
        };
        if (!mutationName) {
          this.dataLocalStore = {
            ...this.dataLocalStore,
            [mappingName]: list,
          };
        } else {
          this.$store.commit(`Collections/${mutationName}`, list);
        }
      }
    },
    unsubscribeHandler() {
      const { subscriptionTitles: titles, clearItems } = this;
      clearItems();
      titles.forEach(async (item) => (await this.unsubscribe(item)));
      this.unsubscribeCollectionFollowAndUnfollow();
    },
    setSubs() {
      this.asyncSearchingSubscription({
        callback: this.onGetSearchResult,
        canMapNewAttachments: false,
        subscriptionTitle: COMMUNITY_COLLECTIONS_SEARCH_TITLE,
        subscriptionFunc: subscribeSearchResultToVerifiedArea,
      });
      this.asyncSearchingSubscription({
        subscriptionFunc: subscribeSearchWorkspacePagesResult,
        callback: this.onGetSearchResult,
        canMapNewAttachments: false,
        subscriptionTitle: COMMUNITY_WS_PAGES_SEARCH_TITLE,
      });
      this.subscribeCollectionFollowAndUnfollow();
    },
    onGetSearchResult({ data }) {
      this.setSpinner(true);
      const { data: searchResult = [], queryName = '', scrollID } = data ?? {
      };

      let mappedKey = SEARCH_V2_QUERIES[queryName];

      // add condition for verified manufacturers
      if (queryName === 'searchWorkspacePages' && searchResult.length && searchResult[0].isVerified === true) {
        mappedKey = 'verifiedWsPages';
      }

      if (mappedKey === 'collections') {
        this.setSpinner(false);
        return;
      }

      let result = searchResult;
      result = result.map(({
        collectionName: name,
        collectionInfo: {
          productsCount,
          publishExpirationDate = false,
          follow = false,
          creator,
        } = {
        },
        ...rest
      } = {
      }) => ({
        ...rest,
        ...(mappedKey === 'collections' && {
          name,
        }),
        publishExpirationDate,
        productsCount,
        follow,
        creator,
      }));
      const { [mappedKey]: currentItems = [] } = this.dataStore || {
      };
      this.dataStore = {
        ...this.dataStore,
        [mappedKey]: uniqBy([...currentItems, ...result], 'id'),
      };
      this.scrollIDs = {
        ...this.scrollIDs,
        [mappedKey]: !result.length ? null : scrollID,
      };
      this.tempAsyncItems = {
        ...this.tempAsyncItems,
        [mappedKey]: 'value',
      };
      this.setFetchForMapping(mappedKey, false);

      const isObjectHasNullProp = Object.values(this.tempAsyncItems).some(item => !item);
      if (isObjectHasNullProp) return;
      this.setSpinner(false);
    },
    async searchAppropriateItems({ mappingName, scrollID } = {
    }) {
      const requestObject = this.getItemFromCollectionSearch(mappingName);
      if (!requestObject) return;
      const { specificAsyncQueryForRequest: requestName } = requestObject || {
      };
      if (!requestName) return;
      const { request, initVariables } = this[requestName] || {
      };
      if (!request) return;
      const variables = {
        ...initVariables,
        workspaceId: this.activeWorkspaceId,
        limit: DEFAULT_LIMIT,
        requestId: this.currentRequestId,
        scrollID,
      };

      this.setSpinner(true);
      if (this.useLazyLoading) {
        this.setFetchForMapping(mappingName, true);
      }
      try {
        await request(variables);
      } catch (e) {
        console.error(e);
      }
    },
    clearItems() {
      this.dataStore = {
      };
      this.dataLocalStore = {
      };
      this.scrollIDs = {
      };
      this.setInitTempAsyncObj();
    },
    onIntersect({
      isIntersecting, entries, collection: { mappingName } = {
      },
    }) {
      if (isIntersecting) {
        const { id } = entries[0].target || {
        };
        if (!id) return;
        const { [mappingName]: scrollID } = this.scrollIDs || {
        };
        if (!scrollID) {
          this.$emit('onIntersect', {
            intersectedId: id, mappingName,
          });
          return;
        }
        const { [mappingName]: list } = this.dataStore || {
        };
        if (!list) return;
        const { id: lastId } = last(list) || {
        };
        if (!lastId) return;
        if (id !== lastId) return;

        if (mappingName === 'collections') {
          this.getCommunityCollections({
            scrollID,
          });
        } else {
          this.searchAppropriateItems({
            mappingName,
            scrollID,
          });
        }
      }
    },
    setFetchForMapping(mapping, fetching) {
      switch (mapping) {
      case COMMUNITY_PAGES_MAPPING:
        this.setIsFetching({
          communityPages: fetching,
        });
        break;
      case VERIFIED_WS_PAGES_MAPPING:
        this.setIsFetching({
          verifiedWsPages: fetching,
        });
        break;
      }
    },
    setSpinner(val) {
      if (!this.useLazyLoading) {
        this.spinner(val);
      }
    },
  },
};
</script>

<template>
  <div>
    <div
      :class="[!showWarning && 'justify-end']"
      class="image-focused__wrapper d-flex">
      <div
        v-if="showWarning"
        class="row mx-0 align-center justify-center d-flex mt-2 pl-2">
        Click Here before you paste from clipboard
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppFocusWarning',
  props: {
    showWarning: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped lang="scss">
.image-focused {
  &__wrapper {
    height: 23px;
    font-size: 95%;
    word-break: break-word!important;
  }
}
</style>

import BaseResource from '@/utils/files/BaseResource';
const AWS_RESOURCE = 'aws file';
/**
 * Uploaded File to S3
 */
export default class AwsResource extends BaseResource {
  constructor({ file }) {
    super({
      file,
    });
  }
  getResourceType() {
    return AWS_RESOURCE;
  }
}

import { mapGetters } from 'vuex';
import { COLLECTION } from '@/constants/cores';
import { TYPE_WORKSPACE_SHARE } from '@/constants';
import { getUserRolesListById } from '@/utils/accessLayer';
import { TYPE_VIEWER } from '@/constants/userPermissions';
import { omit } from 'lodash';
export default {
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('UserRoles', ['canShareCollectionWithLimit',
      'canShareCollectionWithNoLimit', 'findUserRoleInLibraryForActiveHeader',
      'canShareCustomLibrary', 'canShareLibraryOrCollection', 'usersRolesListInCollection']),
    getUserRole() {
      if (this.useLibraryPermissionsNewWay) {
        const rolesList = this.getUserRolesList;
        if (rolesList.length) {
          return rolesList;
        }
        return this.usersRolesListInCollection;
      }
      return this.item?.type || this.findUserRoleInLibraryForActiveHeader;
    },
    getUserRolesList() {
      const { userId } = this;
      return getUserRolesListById({
        userId,
        users: this.item.members,
      });
    },
    canWorkWithShareLimit() {
      if (this.dialogType == TYPE_WORKSPACE_SHARE) {
        return false;
      }
      return this.canShareCollectionWithLimit(this.getUserRole);
    },
    canWorkWithModifyShare() {
      return this.canShareLibraryOrCollection(this.getUserRole, this.inviteToTextType, this.dialogType);
    },
    // used when sharing a collection inside a library
    permissionsByLibrary() {
      const { members } = this;
      if (!members) return [];

      const perms = members.reduce((result, member) => {
        const { libraryGrants } = member;
        if (libraryGrants?.length) {
          libraryGrants.forEach(libraryGrant => {
            const { id, name } = libraryGrant;
            if (!result[id] && id) {
              result[id] = {
                name,
                id,
                values: [],
              };
            }
            const omittedObject = omit({
              libraryGrant,
              ...member,
            }, ['directGrant', 'libraryGrants']);
            result[id]?.values.push(omittedObject);
          });
          return result;
        }
      }, {
      });
      return perms ? Object.values(perms) : [];
    },
    useLibraryPermissionsNewWay() {
      return this.inviteToTextType == COLLECTION;
    },
  },
  methods: {
    getRoleByPermission(permission) {
      const { type } = permission?.directGrant || {
      };
      if (this.useLibraryPermissionsNewWay && type) {
        return permission?.directGrant?.type;
      }
      return permission?.type || TYPE_VIEWER;
    },
  },
};

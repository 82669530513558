<template>
  <div
    v-if="!editMode && !isExpandedMode">
    {{ valueForRendering }} {{ header.column.shortPref }}
  </div>
  <div
    v-else>
    <v-text-field
      v-model="newValue"
      v-currency="currencyOptions"
      :autofocus="!expandedRow"
      :disabled="!editMode"
      class="border-outline sourcery__filled-grey"
      filled
      hide-details
      @blur="update" />
  </div>
</template>
<script>
import ProductCellCurrencyNumber from '@/mixins/ProductCellCurrencyNumber';
export default {
  name: 'ProductCellNumberV2',
  mixins: [ProductCellCurrencyNumber],
  props: {
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    header: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isFormula() {
      const {
        column = {
        },
      } = this.header || {
      };
      const { type = '' } = column;
      return type === 'FORMULA_NUMBER';
    },
    valueForRendering() {
      const { value, checkIsInteger, $formatCurrency } = this;
      return Array.isArray(value) ? value.join(', ') :
        checkIsInteger($formatCurrency(value).replace('$', ''));
    },
  },
  methods: {
    calculateNewValue() {
      const val = this.value;
      this.newValue = val && Number.parseFloat(val) !== 0 ? this.valueForRendering : this.isFormula ? 0 : '';
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"sourcery__select",attrs:{"disabled":_vm.disabled,"items":_vm.items,"menu-props":{
    nudgeBottom: 42,
  },"color":"black","dense":"","full-width":"","hide-details":"","outlined":"","rounded":"","return-object":""},on:{"change":function($event){return _vm.$emit('resort', _vm.selectedItem)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"sourcery__icon-wrapper black size-22"},[_c('img',{attrs:{"src":require("@/assets/icons/chevron-down-icon.svg")}})])]},proxy:true},{key:"selection",fn:function(ref){
  var item = ref.item;
  var attrs = ref.attrs;
return [_c('span',_vm._b({staticClass:"fs-14 text-uppercase"},'span',attrs,false),[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
  var item = ref.item;
  var on = ref.on;
return [_c('v-list-item',_vm._g({class:{'v-item--active v-list-item--active': item.name === _vm.selectedItem.name }},on),[_c('v-list-item-title',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}}]),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})}
var staticRenderFns = []

export { render, staticRenderFns }
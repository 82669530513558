var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container table-container__shadowed"},[_c('v-data-table',{attrs:{"item-key":"id","must-sort":"","items":_vm.data,"hide-default-footer":"","disable-pagination":"","data-test":"collections_table","elevation":0,"mobile-breakpoint":0,"headers":_vm.headersForRendering},on:{"click:row":function($event){return _vm.navigateNext($event, _vm.group)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.onIntersect),expression:"onIntersect",modifiers:{"once":true}}],staticStyle:{"display":"flex","align-items":"center"},attrs:{"id":item.id}},[_c('div',{staticClass:"project-title-wrapper"},[_c('v-row',{staticClass:"ma-0 flex-nowrap",attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"overflow-dots__collections-wrapper"},'div',attrs,false),on),[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"overflow-dots__collections",attrs:{"data-test":"collection_name"}},[_vm._v(" "+_vm._s(item.name || item.title)+" ")]),_c('v-badge',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUnreadComments(item)),expression:"showUnreadComments(item)"}],staticClass:"collections-table__unread-comments-badge",attrs:{"content":item.commentsUnread}})],1)])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])],1)],1)])]}},{key:"item.members",fn:function(ref){
var item = ref.item;
return [_c('Members',{attrs:{"members":_vm.getCollItemMembers(item),"member-count":_vm.getCollItemMemberCount(item)}})]}},{key:"item.creator",fn:function(ref){
var item = ref.item;
return [_c('Creator',{attrs:{"member":item.creator}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!_vm.isSharedPage)?_c('v-icon',_vm._g({staticClass:"brightness-icon",attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click-action-menu', {
          item: item,
          event: $event,
        })}}},_vm.$listeners),[_vm._v(" mdi-dots-vertical ")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
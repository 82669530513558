import BaseResource from '@/utils/files/BaseResource';
import ImageResizer from '@/utils/ImageResizer';
const FILE_RESOURCE = 'file';
import { DEFAULT_THUMBNAILS_SIZES } from '@/constants/thumbnails';
import { calculateAspectRatioFit } from '@/utils';
/**
 * Not uploaded File
 */
export default class FileResource extends BaseResource {
  /**
   * @param file
   * @param pdfProvider - only if file is pdf type
   */
  constructor({ file, pdfProvider = null }) {
    super({
      file,
    });
    if (this.isImageType()) {
      this.setImageResizer(this.getInternalFile());
    }
    if (this.isPdfType() && pdfProvider) {
      this.pdfProvider = pdfProvider;
    }
  }
  getInternalFile() {
    return this.getFile()?.file;
  }
  setImageResizer(file) {
    this.resizer = new ImageResizer({
      image: file,
    });
    return this;
  }
  getImageResizer() {
    if (!this.isImageType()) {
      throw new Error('Sorry but file must be an image type');
    }
    return this.resizer;
  }
  async addResizedVersion(width, height) {
    if (!this.isImageType()) {
      throw new Error('Sorry but file must be an image type');
    }
    const imageResizer = this.getImageResizer();
    await imageResizer.parseImage();
    await imageResizer.findAspectRadioFitAndResize(width, height);
    this.file.resizedFile = imageResizer.getImage();
    return imageResizer.getRatioSize;
  }
  getPdfProvider() {
    return this.pdfProvider;
  }
  /**
   * @param pdfStylesMap - pdf styles to render on page
   * @param thumbnailsSize
   * @param ctx current vue instance
   */
  setResizedPdfStyles(pdfStylesMap, thumbnailsSize, ctx) {
    if (!this.isPdfType()) {
      throw new Error('Sorry but file must be pdf type');
    }
    try {
      if (pdfStylesMap[this.getFileId()]) {
        return this;
      }
      this.getPdfProvider().forEachPage((page) => {
        const { pageNumber, view } = page;
        if (pageNumber == 1) {
          const [widthPdf, heightPdf] = [view[2], view[3]];
          const { width, height } = DEFAULT_THUMBNAILS_SIZES()[thumbnailsSize];
          const previewPdfStyles = calculateAspectRatioFit(widthPdf, heightPdf, width, height);
          ctx.$set(ctx.previewPdfStyles, this.getFileId(), {
            width: `${previewPdfStyles.width}px`,
            height: `${previewPdfStyles.height}px`,
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
  destroyPdf() {
    if (!this.isPdfType()) {
      throw new Error('Sorry but file must be pdf type');
    }
    this.pdfProvider.destroy();
    return this;
  }
  getResourceType() {
    return FILE_RESOURCE;
  }
}

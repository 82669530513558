<template>
  <app-dialog
    v-model="statusDialog"
    :value.sync="statusDialog"
    attach=".main-app"
    persistent
    width="637"
    @keydown.esc="statusDialog = false ">
    <v-expansion-panels
      v-model="statusPanel"
      mandatory>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="header_bottom"
          :hide-actions="hideCloseIconBottom">
          <template #default="{open}">
            <v-icon
              v-if="!open"
              class="icon-closed-header"
              @click.stop="changePanel">
              mdi-chevron-down
            </v-icon>
            <div>
              <slot
                name="bottomHeader"
                :status-header="open"
                :statusDialog="statusDialog"
                :close-modal="closeModal" />
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <slot
            :statusDialog="statusDialog"
            :close-modal="closeModal"
            name="bottomContent" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="header_members"
          :hide-actions="hideCloseIconTop">
          <template #default="{open}">
            <v-icon
              v-if="!open"
              class="icon-closed-header"
              @click.stop="changePanel">
              mdi-chevron-down
            </v-icon>
            <div>
              <slot
                :status-header="open"
                name="topHeader"
                :statusDialog="statusDialog"
                :closeModal="closeModal" />
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <slot
            :statusDialog="statusDialog"
            :closeModal="closeModal"
            name="topContent" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </app-dialog>
</template>
<script>
import { TYPE_COLLECTION_SHARE } from '@/constants';
import {
  mapMutations, mapState,
} from 'vuex';
export default {
  name: 'GroupModal',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    bgIconColor: {
      type: String,
      default: 'blue',
    },
    hideCloseIconTop: {
      type: Boolean,
      default: false,
    },
    hideCloseIconBottom: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: TYPE_COLLECTION_SHARE,
    },
  },
  data() {
    return {
      statusPanel: 0,
      dialog: false,
      TYPE_COLLECTION_SHARE,
    };
  },
  computed: {
    ...mapState('Collections', ['sharingDialog']),
    statusDialog: {
      get() {
        return this.sharingDialog;
      },
      set(val) {
        this.setSharingDialog(val);
      },
    },
  },
  watch: {
    statusDialog(val) {
      if (val) this.statusPanel = 0;
    },
  },
  methods: {
    ...mapMutations('Collections', ['setSharingDialog']),
    changePanel() {
      this.statusPanel = !this.statusPanel ? 1 : 0;
    },
    closeModal() {
      this.statusDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.header_members{
  overflow: hidden;
}
 .icon-closed-header {
  position: absolute;
  top: 23px;
  right: 20px;
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}
::v-deep .v-expansion-panel-header {
  display: block;
  padding: 0;
}
</style>

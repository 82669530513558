<template>
  <v-card-actions class="floating-actions justify-center gap-3">
    <ProductAddToProject
      v-if="isNeedToShowAddToProjectButton"
      :item="item">
      <template #buttonWrapper="{ btnText }">
        <v-btn outlined>
          {{ btnText }}
        </v-btn>
      </template>
    </ProductAddToProject>
    <div class="px-0">
      <app-btn
        v-if="isHideDeleteButton"
        :access="canModifyOwnProduct"
        :show-tooltip="!canModifyOwnProduct.allowed"
        btn-class="white--text"
        color="red"
        elevation="0"
        bottom
        @click="$emit('delete-current-product')">
        delete
      </app-btn>
      <ProductDuplicateV2
        v-if="isFollowButtonVisible"
        :duplicate-type="TYPE_FOLLOW"
        :duplicated-collection-id="followedCollectionId"
        :duplicated-product-ids="[productId]"
        :is-store-product-preload="isStoreProductPreload"
        :product-from-menu="item"
        :user-role="findUserRoleInLibraryForActiveHeader">
        <template #duplicateButton="{ disableAction, btnText, loading }">
          <div class="d-flex">
            <app-btn
              :disabled="disableAction"
              :loading="loading"
              :show-tooltip="disableAction"
              bottom
              outlined>
              {{ btnText }}
            </app-btn>
          </div>
        </template>
      </ProductDuplicateV2>
    </div>
    <template v-if="!showAllActionsForReadOnly">
      <v-btn
        outlined
        @click="$emit('show-product-modal', false)">
        Cancel
      </v-btn>
      <app-btn
        v-if="isEditModalRelatedVariant || isUserCanEditProduct"
        :access="canModifyOwnProduct"
        :disabled="disabledEditMode"
        :show-tooltip="!canModifyOwnProduct.allowed"
        btn-class="white--text"
        color="blue"
        bottom
        @click="$emit('save')">
        Update
      </app-btn>
      <v-btn
        v-else
        :disabled="isDisabledCreateButton"
        color="blue"
        class="white--text"
        elevation="0"
        @click="$emit('save')">
        Save
      </v-btn>
    </template>
    <template v-else-if="showAllActionsForReadOnly && !$route.path.includes('/shared')">
      <ProductDuplicateV2
        v-if="hasAccessToProduct || isUserCanEditProduct || showAllActionsForCollectionFollower"
        :is-store-product-preload="isStoreProductPreload"
        :duplicate-type="TYPE_DUPLICATE"
        :user-role="findUserRoleInLibraryForActiveHeader"
        :duplicated-collection-id="followedCollectionId"
        :product-from-menu="item"
        :duplicated-product-ids="[productId]">
        <template #duplicateButton="{ disableAction, btnText }">
          <div class="d-flex">
            <app-btn
              :disabled="disableAction"
              :loading="showSpinner"
              :show-tooltip="disableAction"
              bottom
              outlined>
              {{ btnText }}
            </app-btn>
          </div>
        </template>
      </ProductDuplicateV2>
      <ProductDuplicateV2
        :duplicate-type="TYPE_FOLLOW"
        :duplicated-collection-id="followedCollectionId"
        :duplicated-product-ids="[productId]"
        :is-store-product-preload="isStoreProductPreload"
        :product-from-menu="item"
        :user-role="findUserRoleInLibraryForActiveHeader">
        <template #duplicateButton="{ disableAction, btnText, loading }">
          <div class="d-flex">
            <app-btn
              :disabled="disableAction"
              :loading="loading"
              :show-tooltip="disableAction"
              bottom
              outlined>
              {{ btnText }}
            </app-btn>
          </div>
        </template>
      </ProductDuplicateV2>
      <app-btn
        v-if="hasAccessToProduct || isUserCanEditProduct || showAllActionsForCollectionFollower"
        :access="showAllActionsForCollectionFollower ? showAllActionsForCollectionFollowerAccessDetails : canModifyOwnProduct"
        :loading="showSpinner"
        :show-tooltip="!showAllActionsForCollectionFollower && !canModifyOwnProduct"
        btn-class="white--text"
        color="blue"
        elevation="0"
        bottom
        @click="$emit('changeToEdit')">
        Edit
      </app-btn>
    </template>
  </v-card-actions>
</template>
<script>
import ProductAddToProject from '@/components/Product/ProductAddToProject';
import ProductDuplicateV2 from '@/components/Product/ProductDuplicateV2';
import DisableEditMode from '@/mixins/DisableEditMode';
import {
  mapGetters, mapState,
} from 'vuex';
import {
  TYPE_DUPLICATE, TYPE_FOLLOW, SHARED_COLLECTION_PAGE_NAME,
} from '@/constants';
export default {
  name: 'DialogActions',
  components: {
    ProductAddToProject,
    ProductDuplicateV2,
  },
  mixins: [DisableEditMode],
  props: {
    item: {
      type: Object,
      default: null,
    },
    canWorkWithModifyProduct: {
      type: Object,
      default: () => {
      },
    },
    selectedCollectionToCreateProduct: {
      type: Object,
      default: null,
    },
    hasAccessToProduct: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      default: '',
    },
    followedCollectionId: {
      type: String,
      default: '',
    },
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
    userRoleInCollection: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      TYPE_DUPLICATE,
      TYPE_FOLLOW,
    };
  },
  computed: {
    ...mapState(['showSpinner', 'activeHeader']),
    ...mapGetters('Collections', ['isReadOnlyModalVariant', 'isCompareModalVariant', 'isEditModalVariant', 'isAddModalVariant', 'isSpecSheetModalVariant', 'isUpdateReferencedProduct']),
    ...mapGetters({
      isActiveUserGuestInWorkspace: 'Workspace/isActiveUserGuestInWorkspace',
      findUserRoleInLibraryForActiveHeader: 'UserRoles/findUserRoleInLibraryForActiveHeader',
    }),
    isNeedToShowAddToProjectButton() {
      return this.showAllActionsForReadOnly && !this.$route.path.includes('/shared');
    },
    isDisabledCreateButton() {
      return (this.isAddModalVariant || this.isSaveAsNewVariant) && !this.selectedCollectionToCreateProduct?.id;
    },
    isSharedCollectionPage() {
      const { name = '' } = this.$route ?? {
      };
      return SHARED_COLLECTION_PAGE_NAME === name;
    },
    isHideDeleteButton() {
      return !this.isSharedCollectionPage && (
        this.showAllActionsForReadOnly
        && (this.hasAccessToProduct || this.isUserCanEditProduct || this.showAllActionsForCollectionFollower)
      );
    },
    isFollowButtonVisible() {
      return this.isSharedCollectionPage;
    },
    isEditModalRelatedVariant() {
      const { isEditModalVariant, isUpdateReferencedProduct } = this;
      return isEditModalVariant || isUpdateReferencedProduct;
    },
    isUserCanEditProduct() {
      return ['creator', 'editor', 'owner'].includes(this.userRoleInCollection);
    },
    canModifyOwnProduct() {
      if (this.isUserCanEditProduct) {
        return {
          allowed: true,
        };
      } else {
        return this.canWorkWithModifyProduct;
      }
    },
    showAllActionsForCollectionFollower() {
      return this.findUserRoleInLibraryForActiveHeader === 'follower';
    },
    showAllActionsForCollectionFollowerAccessDetails() {
      return {
        allowed: false,
        reason: 'product_is_in_followed_collection',
      };
    },
    showAllActionsForReadOnly() {
      return this.isReadOnlyModalVariant || this.isCompareModalVariant || this.isSpecSheetModalVariant;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .item-wrapper {
  margin-top: 20px;
  height: auto;
}
::v-deep .v-application p {
  margin-bottom: 0px !important;
}
</style>

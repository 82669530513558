import FileExtensions from '@/constants/fileExtensions';
import {
  isIesExt, isPhotometricExt,
} from '@/utils/fileExtensions';
const BASE_RESOURCE = 'base';
export default class BaseResource {
  constructor({ file }) {
    this.file = file;
  }
  getResourceType() {
    return BASE_RESOURCE;
  }
  getFile() {
    return this.file;
  }
  getUrl() {
    return this.getFile()?.url || '';
  }
  isImageType() {
    return this.getFileType()?.includes(FileExtensions.IMAGE);
  }
  isPdfType() {
    return this.getFileType()?.includes(FileExtensions.PDF);
  }
  isIesType() {
    return isIesExt(this.getFilename());
  }
  isPhotometricType() {
    return isPhotometricExt(this.getFilename());
  }
  getFileId() {
    return this.getFile()?.id;
  }
  getFilename() {
    const file = this.getFile();
    return file?.filename || file?.name;
  }
  getFileType() {
    return this.getFile()?.type || '';
  }
}

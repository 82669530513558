import { parseStorageKey } from '@/utils';
import { Storage } from 'aws-amplify';
import CollectionProductsApi from '@/services/graphql/collectionProducts';
import CollectionsApi from '@/services/graphql/collections';
import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import PRODUCT_MODE from '@/constants/productMode';
import {
  EventBus,
  EDITED_FOLLOWED_PRODUCT,
} from '@/utils/eventBus';
const MODE_FOLLOW = 'follow';
const textBtnOwnProduct = 'Update Current';
const textBtnFollowedProduct = 'Update Original';
export default {
  data: () => ({
    allowSpinnerOnUpdate: true,
    rowItem: null,
    isCreateNewProduct: false,
    accessUpdateMaster: null,
    masterCollectionName: null,
  }),
  computed: {
    ...mapState({
      productCollectionName: state => state.Collections.productCollectionName,
      activeWorkspaceId: state => state.Workspace.activeWorkspaceId,
      roleInResource: state => state.roleInResource,
    }),
    ...mapGetters('Collections', ['getFullProductById', 'collectionsSchema']),
    ...mapGetters('Libraries', ['getDefaultLibraryId']),
    ...mapGetters('UserRoles', ['canModifyProduct']),
    ...mapGetters({
      useRemoveEditWarningForFollowedProducts: 'FeatureFlags/useRemoveEditWarningForFollowedProducts',
      useRemoveUpdateWarning: 'FeatureFlags/useRemoveUpdateWarning',
    }),
    collectionName() {
      const {
        selectedCollectionToCreateProduct: selectedCollection,
        activeHeader,
        productCollectionName,
      } = this;
      const { name: nameSelectedCollection = '' } = selectedCollection || {
      };
      const { name = '' } = activeHeader || {
      };
      return nameSelectedCollection || name || productCollectionName;
    },
    libraryId() {
      const { libraryId = '' } = this.$route.params || {
      };
      return libraryId || this.getDefaultLibraryId;
    },
    isOwnMode() {
      const { item, rowItem } = this;
      const mode = item?.mode || rowItem?.mode;
      return mode === PRODUCT_MODE.OWN;
    },
    followedCollectionId() {
      const collectionId = this.item?.collectionId || this.rowItem?.collectionId;
      return collectionId || this.$route.params.id;
    },
    getMasterCollection() {
      if (this.isUpdateReferencedProduct) return this.getMasterCollectionIdInUpdateReference;
      const { item, rowItem } = this;
      const masterCollectionId = item?.data_key_pk || rowItem?.data_key_pk;
      return masterCollectionId ? masterCollectionId.split('#').pop() : false;
    },
    getMasterCollectionIdInUpdateReference() {
      return this.item?.masterCollectionId;
    },
    getMasterCollectionUrl() {
      const routeObj = {
        name: 'collection-library',
        params: {
          id: this.getMasterCollection,
        },
      };
      const url = this.$router.resolve(routeObj)?.href;
      return url;
    },
    getMasterProduct() {
      if (this.isUpdateReferencedProduct) return this.getMasterProductIdInUpdateReference;
      const { item, rowItem } = this;
      const masterProductId = item?.data_key_sk || rowItem?.data_key_sk;
      return masterProductId ? masterProductId.split('#').pop() : false;
    },
    getMasterProductIdInUpdateReference() {
      return this.item?.masterProductId;
    },
    textUpdateProduct() {
      const { isOwnMode } = this;
      const textOwnProduct = 'Would you like to update the current product or create a new one as a copy?';
      const masterCollectionRoute = `<a target="_blank" href=${this.getMasterCollectionUrl}>${this.masterCollectionName}</a>`;
      const textFollowedProduct = `This product has been followed from collection ${masterCollectionRoute},
        would you like to create a copy in the current collection or update the original version?`;
      return {
        textWarning: isOwnMode ? textOwnProduct : textFollowedProduct,
        textBtnCancel: 'Create Copy',
        textBtnAccept: isOwnMode ? textBtnOwnProduct : textBtnFollowedProduct,
      };
    },
    collectionIdForCreateProduct() {
      const {
        selectedCollectionToCreateProduct,
        isUpdateReferencedProduct,
        getMasterCollectionIdInUpdateReference,
        followedCollectionId,
      } = this;
      const { id: selectedCollectionId = '' } = selectedCollectionToCreateProduct || {
      };
      const { mode = '', referencedCollectionId = '' } = this.item || {
      };
      const collectionIdReferenced = mode === MODE_FOLLOW ? referencedCollectionId : getMasterCollectionIdInUpdateReference;
      return isUpdateReferencedProduct ? collectionIdReferenced : (selectedCollectionId || followedCollectionId);
    },
  },
  methods: {
    ...mapActions({
      manageLastProducts: 'StoredProducts/manageLastProducts',
      changesOnUpdateClick: 'Collections/changesOnUpdateClick',
      limitOfProductsInCollectionError: 'Collections/limitOfProductsInCollectionError',
      handleError: 'handleError',
      getMyRoleInResource: 'getMyRoleInResource',
    }),
    async hasAccessFromOriginCollection() {
      const { item, rowItem } = this;
      const mode = item?.mode || rowItem?.mode;
      if (mode === PRODUCT_MODE.FOLLOW && this.getMasterCollection) {
        await this.getMyRoleInResource({
          resourceId: this.getMasterCollection,
        });
        const role = this.roleInResource;
        return role && this.canModifyProduct(role).allowed;
      }
      return false;
    },
    async updateProductField({ col, ev, rowId, mode, allowSpinnerOnUpdate, colHeader }) {
      let { parseAttachment, onSaveInTable, generateProduct, showProductModal } = this;
      if (col === 'Choose Collection' || colHeader?.value === 'Choose Collection') {
        const { value } = ev || {
        };
        this.selectedCollectionToCreateProduct = value;
        return;
      }
      this.allowSpinnerOnUpdate = allowSpinnerOnUpdate;
      if (ev.cellType === 'attachment') {
        ev.values = await parseAttachment(ev);
      }
      const newItem = {
        col,
        value: ev.value ? ev.value : ev.values,
      };
      if (showProductModal) {
        this.$set(this.rowData, newItem.col, newItem.value);
        return;
      }
      this.rowItem = this.getFullProductById(rowId);
      //replace new ids img for new created product
      if (ev.isCreateNewProduct && ev.cellType === 'attachment') {
        this.rowItem[col] = ev.values.map(e => ({
          id: e,
        }));
        this.isCreateNewProduct = ev.isCreateNewProduct;
      }
      onSaveInTable(mode, generateProduct(newItem), rowId);
    },
    async parseAttachment({ values }) {
      return Promise.all(values.map(async val => typeof val === 'string' ? val : await this.parseAttachmentElse(val)));
    },
    async editFollowedProduct(productId) {
      this.spinner(true);
      try {
        const { data } = await CollectionProductsApi.editFollowedProduct({
          collectionId: this.isUpdateReferencedProduct ?
            this.item?.referencedCollectionId : this.item?.collectionId || this.$route.params.id,
          productId,
          workspaceId: this.activeWorkspaceId,
        });
        EventBus.$emit(EDITED_FOLLOWED_PRODUCT, data?.editFollowedProduct);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async parseAttachmentAsString(attachment) {
      const { key, filename } = parseStorageKey(attachment);
      let data = await Storage.get(
        key, {
          level: 'protected',
        }
      );
      return {
        url: data,
        type: 'image',
        declareAttachmentId: attachment,
        filename,
        id: attachment,
        key: attachment,
      };
    },
    async parseAttachmentElse(attachment) {
      const { identityId, key } = parseStorageKey(attachment.key);
      let data = await Storage.get(
        key, {
          level: 'protected',
          identityId,
        }
      );
      return {
        url: data,
        type: attachment.type,
        declareAttachmentId: attachment.id,
        filename: attachment.filename,
        id: attachment.id,
        key: attachment.key,
      };
    },
    async addProduct(productToCreate) {
      const {
        spinner,
        collectionName,
        libraryId: currentLibraryId,
        collectionIdForCreateProduct: collectionId,
      } = this;
      try {
        spinner(true);
        const { data } = await CollectionProductsApi.createProduct({
          product: productToCreate,
          workspaceId: this.activeWorkspaceId,
          collectionId,
          ...this.isSaveAsNewVariant && {
            fromProject: true,
          },
        });
        const { fields = [] } = data.response || {
        };
        const libraryId = this.isSourceLibraryId ? this.propLibraryId : currentLibraryId;
        const product = {
          fields, collectionId, collectionName, libraryId,
        };
        this.manageLastProducts({
          parse: true,
          ...product,
        });
        const image = data.response.fields.find(el => el.name === 'Image');
        this.$emit('set-new-product-image', {
          image: image?.array?.[0]?.attachment,
          collectionId,
        });
        this.$emit('added-product', data.response);
        return product;
      } catch (err) {
        this.handleError(err);
      } finally {
        spinner(false);
      }
    },
    async saveProduct({ product, productId, collectionId: collectionIdtoReq } = {
    }) {
      const {
        spinner,
        getMasterCollection,
        getMasterProduct,
        followedCollectionId,
        collectionName,
        libraryId,
      } = this;
      const collectionId = this.accessUpdateMaster && getMasterCollection ? getMasterCollection : collectionIdtoReq || followedCollectionId;
      try {
        spinner(true);
        productId = this.accessUpdateMaster && getMasterProduct ? getMasterProduct : productId || this.productId;
        const { data } = await CollectionProductsApi.updateProduct({
          product,
          collectionId,
          productId,
          workspaceId: this.activeWorkspaceId,
        });
        this.changesOnUpdateClick(data.updateProduct.fields);
        const { fields = [] } = data.updateProduct || {
        };
        this.manageLastProducts({
          parse: true,
          fields, collectionId: followedCollectionId, collectionName, libraryId,
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        spinner(false);
      }
    },
    chekIsSaveItemAttachment(item) {
      if (!Array.isArray(item)) {
        return item;
      }
      return item.map((i) => {
        if (i.declareAttachmentId) {
          console.log('err');
          return i.declareAttachmentId;
        } else if (i.id) {
          return i.id;
        } else {
          return i;
        }
      });
    },
    generateProduct({ id, col, value }) {
      const { getColIdByName, chekIsSaveItemAttachment } = this;
      id = id || getColIdByName(col);
      return {
        id,
        [Array.isArray(value) ? 'values' : 'value']: chekIsSaveItemAttachment(value),
      };
    },
    getColIdByName(colName) {
      const { schema } = this.collectionsSchema;
      return schema.find(col => col.name == colName)?.id;
    },
    getColById(colId) {
      const { schema } = this.collectionsSchema;
      return schema.find(col => col.id == colId);
    },
    async getLibraryCollectionName() {
      try {
        const { activeWorkspaceId: workspaceId, getMasterCollection: collectionId } = this;
        const { data } = await CollectionsApi.getLibraryCollectionName({
          collectionId,
          workspaceId,
          hasAccessToOrigin: !!this.roleInResource,
        });
        this.masterCollectionName = data?.response?.name || 'link';
      } catch (err) {
        console.log('getLibraryCollectionName err');
      }
    },
    async isUpdateOrCreate() {
      const { isOwnMode, useRemoveUpdateWarning: flag } = this;
      if (flag && isOwnMode) return textBtnOwnProduct;
      if (!this.isOwnMode) {
        await this.getLibraryCollectionName();
      }
      const {
        textBtnCancel: cancel,
        textWarning: text,
        textBtnAccept: accept,
      } = this.textUpdateProduct;
      return await this.$openConfirm({
        cancelResponse: 'create',
        text,
        cancel,
        accept,
      });
    },
    async getColumnSettings(colId) {
      this.accessUpdateMaster = await this.hasAccessFromOriginCollection();
      const col = this.getColById(colId);
      const isArrayAttachment = col?.type === 'array' && col?.arrayType === 'attachment';
      return {
        col,
        isArrayAttachment,
        showPrompt: !isArrayAttachment && (this.isOwnMode || this.accessUpdateMaster),
      };
    },
    async onShowPrompt() {
      const response = await this.isUpdateOrCreate();
      if (response === 'create') this.isCreateNewProduct = true;
      return !!response;
    },
    async onEditFollowedProduct(productId, isArrayAttachment = false) {
      const { useRemoveEditWarningForFollowedProducts: flag } = this;
      if (!isArrayAttachment && !flag) {
        const confirmEditFollowed = await this.$openConfirm({
          text: 'By editing a followed product, you will make a copy of that product. Are you sure you want to proceed?',
        });
        if (!confirmEditFollowed) return false;
      }
      await this.editFollowedProduct(productId);
      return true;
    },
    async allowToSave(mode, productId, colId) {
      const { showPrompt, isArrayAttachment } = await this.getColumnSettings(colId);
      if (showPrompt) return this.onShowPrompt();
      if (mode === PRODUCT_MODE.FOLLOW) return this.onEditFollowedProduct(productId, isArrayAttachment);
      return true;
    },
    async onSaveInTable(productMode, productToSave, productId) {
      const canSave = await this.allowToSave(productMode, productId, productToSave.id);
      if (!canSave) {
        return;
      }
      if (this.allowSpinnerOnUpdate) {
        this.spinner(true);
      }
      let productToCreate = [];
      const { rowItem, isCreateNewProduct } = this;
      if (isCreateNewProduct) {
        this.collectionsSchema.schema.forEach((s) => {
          if (rowItem[s.name] !== undefined
            || rowItem && rowItem[s.name]?.length !== 0 && rowItem[s.name] !== undefined) {
            productToCreate.push(this.generateProduct({
              id: s.id,
              value: rowItem[s.name] || '',
            }));
          }
        });
      }
      isCreateNewProduct ? await this.addProduct(productToCreate) : await this.saveProduct({
        product: productToSave, productId,
      });
      if (this.allowSpinnerOnUpdate) {
        this.spinner(false);
      }
      this.isCreateNewProduct = false;
    },
  },
};

<template>
  <div class="wrapper d-flex align-center">
    <div v-if="!editMode">
      {{ getRenderedValue }}
    </div>
    <template v-else>
      <div class="wrapper__data-picker">
        <app-date-picker
          ref="datepicker"
          :can-save="canSave"
          :date-picker-value="newValue"
          @on-open="menu = true"
          @on-enter="update"
          @on-clear="clearDate"
          @on-cancel="closeDatePicker"
          @on-save="update();"
          @on-date-picker-value-event="setNewValue" />
      </div>
    </template>
    <v-icon
      v-if="isExpandedMode"
      class="wrapper__icon-append">
      mdi-calendar-month-outline
    </v-icon>
  </div>
</template>
<script>
import EditModeChild from '@/mixins/EditModeChildComponent';
import { mask } from 'vue-the-mask';
import { mapMutations } from 'vuex';
import AppDatePicker from '@/components/App/AppDatePicker';
export default {
  name: 'ProductCellDateV2',
  directives: {
    mask,
  },
  components: {
    AppDatePicker,
  },
  mixins: [EditModeChild],
  props: {
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menu: true,
      newValue: null,
      formatMask: '####-##-##',
    };
  },
  computed: {
    canSave() {
      if (!this.newValue) {
        return true;
      } else {
        const lengthOfDate = 10;
        const year = Number.parseInt(this.newValue.split('-')[0]) > 1950;
        const month = Number.parseInt(this.newValue.split('-')[1]) <= 12;
        const day = Number.parseInt(this.newValue.split('-')[2]) <= 31;
        return this.newValue && this.newValue.length === lengthOfDate
          && year && month && day;
      }
    },
    getRenderedValue() {
      if (!this.value) {
        return null;
      }
      const value = Number(this.value);
      if (isNaN(value)) {
        return this.value;
      }
      return this.$moment(value).format(this.formatType);
    },
    formatType() {
      return 'DD MMM YYYY'.concat(!this.editMode && ' HH:mm');
    },
  },
  watch: {
    value: {
      handler(val) {
        this.newValue = val;
      },
      immediate: true,
    },
    editMode(val) {
      if (val) {
        this.newValue = this.value;
      }
    },
  },
  methods: {
    ...mapMutations(['openSnackBar']),
    update() {
      if (this.newValue && !this.canSave) {
        this.openSnackBar({
          text: 'Date must be in YYYY-MM-DD format',
        });
        return;
      }
      this.getDatePicker().$refs.menu.save(this.newValue);
      this.$emit('change', {
        value: this.newValue || '',
      });
      this.menu = false;
      this.toggleEditMode();
    },
    clearDate() {
      this.newValue = null;
      this.update();
    },
    setNewValue(val) {
      this.newValue = val;
    },
    getDatePicker() {
      return this.$refs.datepicker;
    },
    closeDatePicker() {
      this.menu = false;
      this.toggleEditMode();
    },
  },
};
</script>
<style scoped lang="scss">
.v-card {
  .wrapper {
    padding-left: 15px;
    min-height: 40px;
    background-color: var(--v-lightGrey-base);

    &__icon-append {
      margin-right: 12px;
      margin-left: auto;
    }

    &__data-picker {
      margin-right: 15px;
      width: 100%;
    }
  }
}
::v-deep .v-btn .v-btn__content {
  color: black !important;
}
</style>

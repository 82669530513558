<template>
  <div>
    <v-data-table
      :loading="isInitData"
      class="m-4"
      disable-sort
      must-sort
      calculate-widths
      fixed-header
      :mobile-breakpoint="0"
      hide-default-footer
      height="380px"
      show-select
      disable-pagination
      :headers="headers"
      :items="items">
      <template
        #header.data-table-select>
        <v-checkbox
          v-if="!isRestoreMode && items.length"
          v-model="isSelectAll"
          hide-details
          :ripple="false"
          @change="changeSelectAll" />
      </template>
      <template #item.data-table-select="{item}">
        <div class="wrapper-prepend-cell">
          <v-checkbox
            v-if="!isRestoreMode"
            v-model="selectDoc"
            hide-details
            multiple
            :value="item"
            :ripple="false" />
          <v-icon
            medium
            color="black"
            @click="setMenuItem({
              item,
              event: $event,
            })">
            mdi-dots-vertical
          </v-icon>
        </div>
      </template>
      <template
        v-for="(header) in headers"
        #[`header.${header.value}`]>
        <div
          :key="header.value"
          v-safe-html="header.text || ''" />
      </template>
      <template
        v-for="(header) in headers"
        #[`item.${header.value}`]="{ item }">
        <document-cell
          :key="header.value"
          :is-restore-mode="isRestoreMode"
          :arr-type-designation="arrTypeDesignation"
          :item="item"
          :tags="tags"
          :header="header"
          v-on="$listeners" />
      </template>
    </v-data-table>
    <document-cell-actions
      :is-disabled-delete="isDisabledDelete"
      :show-activator="false"
      :item="selectedItem"
      :position-x="positionX"
      :position-y="positionY"
      :value.sync="showActionsCard"
      v-on="$listeners">
      <template #default="{item}">
        <slot
          :item="item"
          name="customActions" />
      </template>
    </document-cell-actions>
  </div>
</template>
<script>
import DocumentCell from '@/components/ProjectDetails/DocumentCell';
import DocumentCellActions from '@/components/ProjectDetails/DocumentCell/DocumentCellActions';
import AppActionDotsMenu from '@/mixins/AppActionDotsMenu';
export default {
  name: 'ProjectTableDocuments',
  components: {
    DocumentCellActions,
    DocumentCell,
  },
  mixins: [AppActionDotsMenu],
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    isRestoreMode: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    arrTypeDesignation: {
      type: Array,
      default: () => [],
    },
    isInitData: {
      type: Boolean,
      default: false,
    },
    currentTab: {
      type: String,
      default: 'designDocument',
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSelectAll: false,
      selectedDocuments: [],
    };
  },
  computed: {
    selectDoc: {
      get() {
        return this.selectedDocuments;
      },
      set(val) {
        if (val.length === this.items.length) this.isSelectAll = true;
        else if (this.isSelectAll) this.isSelectAll = false;
        this.selectedDocuments = val;
      },
    },
    isDisabledDelete() {
      return Boolean(this.selectedDocuments.length);
    },
    projectId() {
      return this.$route.params.id;
    },
  },
  watch: {
    isModalOpen(val) {
      if (!val) {
        this.selectedDocuments = [];
        this.isSelectAll = false;
      }
    },
    currentTab() {
      this.selectedDocuments = [];
    },
    selectedDocuments(val) {
      this.$emit('update:selectedDoc', val);
    },
  },
  methods: {
    changeSelectAll(val) {
      if (val) this.selectDoc = this.items;
      else this.selectDoc = [];
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep td, ::v-deep th {
  border-bottom: 1px solid #E4E4E4 !important;
  &:first-child {
    border-left: 1px solid #E4E4E4 !important;
  }
  border-right: 1px solid #E4E4E4 !important;
}
::v-deep td {
  position: relative;
  cursor: pointer;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 21px;
  &:first-child {
    left: 0;
    position: sticky;
    z-index: 5;
    background-color: #fff;
  }
}
::v-deep th {
  z-index: 11 !important;
  box-sizing: content-box;
  &:first-child {
    position: sticky;
    left: 0;
    width: 24px !important;
    z-index: 12 !important;
    background-color: #fff !important;
  }
  white-space: nowrap;
  border-top: 1px solid #E4E4E4 !important;
  div {
    font-size: 16px;
    color: var(--v-lightBlack-base) !important;
    line-height: 21px;
    font-style: normal;
    font-weight: normal;
  }
}
::v-deep table {
  table-layout: fixed;
}
::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.wrapper-prepend-cell {
  padding: 0 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>

import { adjustImageSize } from '@/utils/index';
export default class ImageResizer {
  #srcWidth = 0;
  #srcHeight = 0;
  #widthImgRatio = 0;
  #heightImgRatio = 0;
  #image = null;
  constructor({ image, width = 0, height = 0 }) {
    this
      .setImage(image)
      .setImageWidth(width)
      .setImageHeight(height);
  }
  setImage(image) {
    this.#image = image;
    return this;
  }
  setImageHeight(height) {
    this.#srcHeight = height;
    return this;
  }
  get getRatioSize() {
    return {
      width: this.#widthImgRatio,
      height: this.#heightImgRatio,
    };
  }
  setRatioSize(sizeImg) {
    const { width, height } = sizeImg;
    this.#widthImgRatio = Math.round(width);
    this.#heightImgRatio = Math.round(height);
  }
  setImageWidth(width) {
    this.#srcWidth = width;
    return this;
  }
  async parseImage() {
    const { height, width } = await this.loadImage();
    this
      .setImageHeight(height)
      .setImageWidth(width);
    return this;
  }
  loadImage() {
    const dataUrl = window.URL.createObjectURL(this.getImage());
    return new Promise(resolve => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataUrl;
    });
  }
  async getImageInfo() {
    return {
      srcWidth: this.getImageWidth(),
      srcHeight: this.getImageHeight(),
      image: this.getImage(),
    };
  }
  getImageHeight() {
    return this.#srcHeight;
  }
  getImageWidth() {
    return this.#srcWidth;
  }
  getImage() {
    return this.#image;
  }
  calculateAspectRatioFit({ srcWidth, srcHeight, maxWidth, maxHeight }) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return {
      width: srcWidth * ratio, height: srcHeight * ratio,
    };
  }
  async findAspectRadioFitAndResize(maxWidth, maxHeight) {
    const { srcWidth, srcHeight } = await this.getImageInfo();
    const { width, height } = this.calculateAspectRatioFit({
      srcWidth,
      srcHeight,
      maxWidth,
      maxHeight,
    });
    await this.resizeTo(width, height);
    return this;
  }
  async resizeTo(width, height) {
    const resizedImage = await adjustImageSize(width, height, this.getImage());
    this.setRatioSize({
      width, height,
    });
    this.setImage(resizedImage);
    return this;
  }
}

<template>
  <div :class="[wrapperClass]">
    <!-- for add modal only -->
    <div
      v-if="addModal"
      class="px-7 pb-3 lightGrey">
      <v-text-field
        v-model="searchText"
        :disabled="disabled"
        :placeholder="placeholder"
        autocomplete="off"
        color="black"
        class="ma-0 pa-0 flex-grow-0 border-outline"
        clearable
        dense
        flat
        hide-details
        solo />
    </div>
    <!-- for product info -->
    <div
      v-else
      class="pa-4 d-flex align-center justify-center lightGrey">
      <v-text-field
        v-model="searchText"
        :disabled="disabled"
        :placeholder="placeholder"
        autocomplete="off"
        color="black"
        class="ma-0 pa-0 flex-grow-0 sourcery__condensed-form"
        clearable
        dense
        flat
        hide-details
        solo />
    </div>
    <div
      ref="searchResults"
      :style="searchItemsHeight"
      :class="[searchWrapperClass]"
      @scroll="onScroll">
      <slot
        name="items"
        v-bind="{ searchItems, searchOn, searchText }" />
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import {
  debounce, throttle,
} from 'lodash';
import { DEBOUNCE_TIME_FOR_SEARCHING } from '@/constants';
export default {
  name: 'AppSearchRowsProvider',
  props: {
    addModal: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: String,
      default: '12',
    },
    compareFunction: {
      type: Function,
      default: (items, searchText, compareParam = 'value') => items.filter(item => {
        if (!searchText) {
          return true;
        }
        return item[compareParam].toLowerCase().includes(searchText.toLowerCase());
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Search:',
    },
    searchWrapperClass: {
      type: String,
      default: 'search-items__wrapper',
    },
    searchItemsHeight: {
      type: String,
      default: '',
    },
    wrapperClass: {
      type: String,
      default: 'search-component__wrapper',
    },
  },
  data() {
    return {
      searchText: '',
      searchItems: [],
    };
  },
  computed: {
    searchOn() {
      return !!this.searchText;
    },
  },
  watch: {
    searchText: {
      handler: debounce(function () {
        this.searchItems = this.compareFunction(this.items, this.searchText);
      }, DEBOUNCE_TIME_FOR_SEARCHING),
    },
    items: {
      handler() {
        this.searchItems = this.compareFunction(this.items, this.searchText);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      setScrollValueInProductDialog: 'setScrollValueInProductDialog',
    }),
    onScroll: throttle(function() {
      this.setScrollValueInProductDialog(true);
    }, DEBOUNCE_TIME_FOR_SEARCHING),
  },
};
</script>
<style scoped lang="scss">
.search-component__wrapper {
  flex-grow: 1;
  overflow-y: auto;

  &-equal {
    min-height: 0;
    height: 100%;
  }

  &-equal_fixed {
    height: 100%;
  }
}
.search-items {
  &__wrapper {
    overflow: auto;
    height: calc(100% - 148px);
    &-base {
      overflow: auto;
      height: calc(100%);
    }
    &-reference {
      overflow: auto;
      height: calc(100% - 118px);
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        height: 100%;
      }
    }
    &-equal {
      overflow: auto;
      height: calc(100% - 68px);
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-combobox',{ref:"menu",class:{'disabled-input':!_vm.editMode},attrs:{"items":_vm.items,"dense":"","hide-no-data":"","hide-selected":"","deletable-chips":"","disabled":_vm.readMode,"append-icon":"","hide-details":"","allow-overflow":"","menu-props":{
      auto:true,
      attach:true,
      width:'180px',
      maxWidth:'180px',
    },"chips":""},on:{"change":_vm.save},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"close":!_vm.readMode},on:{"click:close":_vm.removeTag}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-truncate"},on),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item))])])],1)]}}]),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }